import { apiAction } from 'lib/common/middleware/backOfficeApiMiddleware';
import { getProductionItemRequest } from 'lib/Production/services/production';

export const RECEIVE_PROMO_ITEMS_RESPONSE = 'RECEIVE_PROMO_ITEMS_RESPONSE';
export const UPDATE_PROMO_ITEMS_REQUEST = 'UPDATE_PROMO_ITEMS_REQUEST';

const receivePromoItems = ({ country, promoType }) => (data) => ({
  type: RECEIVE_PROMO_ITEMS_RESPONSE,
  country,
  promoType,
  items: data['hydra:member'],
  total: data['hydra:totalItems'],
});

export function fetchPromoItems(request) {
  const {
    filter, pagination, order, country, promoType,
  } = request;
  return apiAction(
    {
      request: getProductionItemRequest({
        ...filter,
        workflowName: promoType,
        country,
      }, pagination, order),
      onSuccess: receivePromoItems({ country, promoType }),
      label: `promo.${promoType}.${country}`,
    },
  );
}

export function updatePromoItemsRequest(request) {
  return {
    ...request,
    type: UPDATE_PROMO_ITEMS_REQUEST,
  };
}
