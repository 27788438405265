import { apiAction } from 'lib/common/middleware/backOfficeApiMiddleware';
import { getAutoSendProductionItemRequest } from 'lib/Production/services/production';

export const RECEIVE_AUTO_SINGLEJOB_ITEMS_RESPONSE = 'RECEIVE_AUTO_SINGLEJOB_ITEMS_RESPONSE';
export const UPDATE_AUTO_SINGLEJOB_REQUEST = 'UPDATE_AUTO_SINGLEJOB_REQUEST';

const receiveAutoSingleItems = (country) => (data) => ({
  type: RECEIVE_AUTO_SINGLEJOB_ITEMS_RESPONSE,
  country,
  items: data['hydra:member'],
  total: data['hydra:totalItems'],
});

export function fetchAutoSingleItems({
  filter, pagination, order, country,
}) {
  return apiAction(
    {
      request: getAutoSendProductionItemRequest({
        ...filter,
        country,
      }, pagination, order),
      onSuccess: receiveAutoSingleItems(country),
      label: `autosinglejob.${country}`,
    },
  );
}

export function updateAutoSingleRequest(request) {
  return {
    ...request,
    type: UPDATE_AUTO_SINGLEJOB_REQUEST,
  };
}
