import {
  RECEIVE_ORDERS_RESPONSE,
  UPDATE_ORDERS_REQUEST,
} from 'lib/ItemState/actions/order';
import { API_END, API_START } from 'lib/common/actions/types';
import { getOrderDefaultFilter } from 'lib/ItemState/services/order';

const initialState = {
  orders: {
    items: [],
    filter: getOrderDefaultFilter(),
    order: { orderedAt: 'DESC' },
    pagination: {
      page: 1,
      perPage: 30,
    },
    total: 0,
    loading: false,
  },
};

function orders(state = initialState, action) {
  switch (action.type) {
    case API_START:
      if (action.payload === 'orders') {
        return { ...state, orders: { ...state.orders, loading: true } };
      }
      break;
    case API_END:
      if (action.payload === 'orders') {
        return { ...state, orders: { ...state.orders, loading: false } };
      }
      break;
    case RECEIVE_ORDERS_RESPONSE:
      return {
        ...state,
        orders: {
          ...state.orders,
          items: action.orders,
          total: action.total,
        },
      };
    case UPDATE_ORDERS_REQUEST:
      return {
        ...state,
        orders: {
          ...state.orders,
          filter: action.request.filter,
          pagination: action.request.pagination,
          order: action.request.order,
        },
      };
    default:
      return state;
  }

  return state;
}

export default orders;
