import { apiAction } from 'lib/common/middleware/backOfficeApiMiddleware';
import { getShipmentRequest } from 'lib/Shipping/services/shipping';

export const RECEIVE_SHIPMENT_RESPONSE = 'RECEIVE_SHIPMENT_RESPONSE';
export const UPDATE_SHIPMENT_REQUEST = 'UPDATE_SHIPMENT_REQUEST';

const receiveAllItems = (country) => (data) => ({
  type: RECEIVE_SHIPMENT_RESPONSE,
  country,
  items: data['hydra:member'],
  total: data['hydra:totalItems'],
});

export function fetchShipment(request) {
  const {
    filter, pagination, order, country,
  } = request;
  return apiAction(
    {
      request: getShipmentRequest({
        ...filter,
        country,
      }, pagination, order),
      onSuccess: receiveAllItems(country),
      label: `shipment.${country}`,
    },
  );
}

export function updateShipmentRequest(request) {
  return {
    ...request,
    type: UPDATE_SHIPMENT_REQUEST,
  };
}
