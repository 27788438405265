import { getRestoreTask } from './request';
import { apiAction } from '../common/middleware/backOfficeApiMiddleware';

export const RECEIVE_S3_RESTORE_TASKS = 'RECEIVE_S3_RESTORE_TASKS';
export const GET_S3_RESTORE_TASKS = 'GET_S3_RESTORE_TASKS';
export const CREATE_S3_RESTORE_TASK = 'CREATE_S3_RESTORE_TASK';
export const UPDATE_S3_RESTORE_TASK_REQUEST = 'UPDATE_S3_RESTORE_TASK_REQUEST';

function receiveS3RestoreTasks(data) {
  return {
    type: RECEIVE_S3_RESTORE_TASKS,
    items: data.items,
    nextPage: data.nextPage,
  };
}

export function fetchS3RestoreTasks(request, nextStart) {
  const { pagination } = request;
  return (dispatch) => {
    dispatch({ type: GET_S3_RESTORE_TASKS });
    dispatch(
      apiAction({
        request: getRestoreTask(pagination.perPage, nextStart),
        onSuccess: receiveS3RestoreTasks,
        label: 's3-restore-service',
      }),
    );
  };
}

export function updateS3RestoreTasksRequest(request) {
  return {
    type: UPDATE_S3_RESTORE_TASK_REQUEST,
    request,
  };
}
