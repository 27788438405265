import {
  RECEIVE_CUSTOMERS,
  UPDATE_DASHBOARD_REQUEST,
} from '../actions/customers';
import { API_END, API_START } from '../../common/actions/types';
import { getDashboardDefaultFilter } from '../services/customers';

const initialState = {
  dashboard: {
    customers: [],
    filter: getDashboardDefaultFilter(),
    order: { createdAt: 'DESC' },
    pagination: {
      page: 1,
      perPage: 10,
    },
    total: 0,
    loading: false,
  },
};

function customers(state = initialState, action) {
  switch (action.type) {
    case API_START:
      if (action.payload === 'customers') {
        return { ...state, dashboard: { ...state.dashboard, loading: true } };
      }
      break;
    case API_END:
      if (action.payload === 'customers') {
        return { ...state, dashboard: { ...state.dashboard, loading: false } };
      }
      break;
    case RECEIVE_CUSTOMERS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          customers: action.customers,
          total: action.total,
        },
      };
    case UPDATE_DASHBOARD_REQUEST:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          filter: action.request.filter,
          pagination: action.request.pagination,
          order: action.request.order,
        },
      };
    default:
      return state;
  }

  return state;
}

export default customers;
