import {
  ACTIVATE_TAB,
  ADD_TAB,
  REMOVE_TAB,
} from 'lib/CustomerComplaint/actions/tab';
import {
  RECEIVE_COMPLAINT_COMMENT_RESPONSE,
  RECEIVE_OLDER_COMPLAINT_COMMENT_RESPONSE,
  RECIEVE_EDITED_COMMENT,
  RECIEVE_EDITED_COMPLAINT,
  OPEN_EDIT_COMPLAINT,
  OPEN_COMPLAINT_TAB,
  RECIEVE_COMPLAINT,
} from 'lib/CustomerComplaint/actions/complaint';

const initialState = {
  activeTab: '',
  tabItems: {},
};

function tabReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TAB:
      return {
        ...state,
        tabItems: {
          ...state.tabItems,
          [action.tabCode]: {
            label: action.tabLabel,
            props: action.tabProps ?? {},
          },
        },
      };
    case REMOVE_TAB: {
      const { [action.tabCode]: oldLabel, ...tabItems } = state.tabItems;
      return { ...state, tabItems };
    }
    case ACTIVATE_TAB:
      return {
        ...state,
        activeTab: action.tabCode,
      };
    case RECEIVE_COMPLAINT_COMMENT_RESPONSE:
      return {
        ...state,
        tabItems: {
          ...state.tabItems,
          ...(state?.tabItems?.[action.newTabCode]) && {
            [action.newTabCode]: {
              label: action.newTabLabel ?? state.tabItems[action.newTabCode].label,
              props: {
                ...state.tabItems[action.newTabCode]?.props,
                complaint: action.complaint ?? state.tabItems[action.newTabCode].props.complaint,
                // Merge existing and reciving comment.
                complaintComments: [
                  ...new Map(
                    (state.tabItems[action.newTabCode]?.props?.complaintComments ?? [])
                      .concat(action.complaintComments.items)
                      .map(item => [item.id, item]),
                  ).values(),
                ]
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
              },
            },
          },
        },
      };
    case RECEIVE_OLDER_COMPLAINT_COMMENT_RESPONSE:
      return {
        ...state,
        tabItems: {
          ...state.tabItems,
          ...(state?.tabItems?.[action.newTabCode]) && {
            [action.newTabCode]: {
              ...state.tabItems[action.newTabCode],
              props: {
                ...state.tabItems[action.newTabCode].props,
                complaintComments:
                  state.tabItems[action.newTabCode].props.complaintComments
                    .concat(action.complaintComments.items),
                noMoreComments: action.complaintComments.items <= 0,
              },
            },
          },
        },
      };
    case RECIEVE_EDITED_COMMENT:
      return {
        ...state,
        tabItems: {
          ...state.tabItems,
          [action.tabCode]: {
            ...state.tabItems[action.tabCode],
            props: {
              ...state.tabItems[action.tabCode].props,
              complaint: {
                ...state.tabItems[action.tabCode].props.complaint,
                // Optimistic Lock Version update.
                version: state.tabItems[action.tabCode].props.complaint.version + 1,
              },
              complaintComments:
                state.tabItems[action.tabCode].props.complaintComments.map((comment) => {
                  if (comment.id === action.editedComment.id) {
                    return action.editedComment;
                  }
                  return comment;
                }),
            },
          },
        },
      };
    case OPEN_EDIT_COMPLAINT:
      return {
        ...state,
        tabItems: {
          ...state.tabItems,
          [action.tabCode]: {
            ...state.tabItems[action.tabCode],
            props: {
              ...state.tabItems[action.tabCode].props,
              edited: !state.tabItems[action.tabCode].props.edited,
            },
          },
        },
      };
    case RECIEVE_EDITED_COMPLAINT:
      return {
        ...state,
        tabItems: {
          ...state.tabItems,
          [action.tabCode]: {
            ...state.tabItems[action.tabCode],
            props: {
              ...state.tabItems[action.tabCode].props,
              edited: false,
              complaint: action.complaint,
            },
          },
        },
      };
    case OPEN_COMPLAINT_TAB:
      return {
        ...state,
        activeTab: action.tabCode,
        tabItems: {
          ...state.tabItems,
          [action.tabCode]: {
            label: action.complaint?.incrementId ?? 'Opening...',
            props: {
              complaint: action.complaint,
            },
          },
        },
      };
    case RECIEVE_COMPLAINT:
      return {
        ...state,
        tabItems: {
          ...state.tabItems,
          ...(state?.tabItems?.[action.tabCode]) && {
            [action.tabCode]: {
              ...state.tabItems[action.tabCode],
              label: action.complaint.incrementId,
              props: {
                ...state.tabItems[action.tabCode].props,
                complaint: action.complaint,
              },
            },
          },
        },
      };
    default:
      return state;
  }
}

export default tabReducer;
