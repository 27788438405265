import {
  shape, arrayOf, number, string, bool,
} from 'prop-types';

export const authType = shape({
  isAuthenticated: bool.isRequired,
  user_id: string,
  name: string,
  email: string,
  picture: string,
  token: string,
  expiry: number,
  roles: arrayOf(string),
  countries: arrayOf(string),
});

export default authType;
