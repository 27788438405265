export const getMappedFilter = (filters, mapping) => Object.keys(filters)
  .reduce(
    (mapped, key) => (
      mapping[key]
        ? { ...mapped, [mapping[key]]: filters[key] }
        : mapped
    ),
    {},
  );

export const getDateFilter = (filter) => {
  if (!filter.from && !filter.to) {
    return null;
  }

  const dateFilter = {};
  if (filter.from) {
    dateFilter.after = filter.from;
  }
  if (filter.to) {
    dateFilter.before = filter.to;
  }

  return dateFilter;
};

export const getRangeFilter = (filter) => {
  if (!filter.gte && !filter.lte) {
    return null;
  }

  const rangeFilter = {};
  if (filter.gte) {
    rangeFilter.gte = filter.gte;
  }
  if (filter.lte) {
    rangeFilter.lte = filter.lte;
  }

  return rangeFilter;
};

export const getBlankDateFilter = () => ({ from: null, to: null });
export const getBlankRangeFilter = () => ({ gte: null, lte: null });
