import { BACKOFFICE_API } from 'lib/common/actions/types';
import {
  apiError,
  apiStart,
  apiEnd,
  apiSuccess,
} from 'lib/common/actions/backOfficeApi';
import BackOfficeUtils from 'lib/BackOfficeUtils';
import Auth from 'lib/auth/Auth';
import { authUser } from 'lib/auth/actions';

export function apiAction({
  request = {},
  onSuccess = () => {},
  onFailure = () => {},
  label = '',
}) {
  return {
    type: BACKOFFICE_API,
    payload: {
      request,
      onSuccess,
      onFailure,
      label,
    },
  };
}

const getRawRequest = async (request) => {
  if (typeof request === 'object') {
    return request;
  }
  if (typeof request === 'function') {
    const data = await request();
    return data;
  }
  return request;
};

const backOfficeApiMiddleware = ({ getState, dispatch }) => (next) => async (action) => {
  next(action);
  if (!action) return {};
  if (action.type !== BACKOFFICE_API) return {};
  const state = getState();

  if (state.auth.expiry < Date.now()) {
    const user = await Auth.currentSession();
    dispatch(authUser(user));
  }

  const accessToken = state.auth.token;

  const {
    request,
    onSuccess,
    onFailure,
    label,
  } = action.payload;

  if (label) {
    dispatch(apiStart(label));
  }

  const rawRequest = await getRawRequest(request);

  return BackOfficeUtils.doRequest(
    rawRequest,
    accessToken,
  )
    .then(({ data }) => {
      dispatch(onSuccess(data));
      dispatch(apiSuccess(label));

      return data;
    })
    .catch((error) => {
      dispatch(apiError(error));
      dispatch(onFailure(error));
      // if (error.response && error.response.status === 403) {
      //   dispatch(accessDenied(window.location.pathname));
      // }

      return error;
    })
    .finally(() => {
      if (label) {
        dispatch(apiEnd(label));
      }
    });
};

export default backOfficeApiMiddleware;
