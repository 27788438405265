import axios from 'axios';
import { stringify } from 'qs';
import { Auth } from 'aws-amplify/lib/index';
import { isEqual } from 'lodash';

const BackOfficeUtils = class {
  static processRequest(type, config) {
    return this.request(type, config).then((response) => {
      const { data } = response;

      if (data['hydra:member']) {
        return { items: data['hydra:member'], total: data['hydra:totalItems'] };
      }

      return data;
    }).catch((apiError) => {
      const error = {
        code: apiError.response.status,
        status: apiError.response.statusText,
      };
      switch (type) {
        case 'prepress':
        case 'customer':
        case 'user':
          error.title = apiError.response.data['hydra:title'];
          error.description = apiError.response.data['hydra:description'];
          break;
        case 'preflight':
        case 'magento_no_gateway':
        case 'magento':
        case 'ppc_service':
        case 'm2_ssh_service':
        default:
          error.title = 'An Error Occurred';
          error.description = 'Unknown Error';
      }
      // eslint-disable-next-line no-console
      console.error('API Error', '\nrequest:', config, '\nerror:', error);
      throw error;
    });
  }

  static doRequest(request, token) {
    const {
      service, method, path, params, data, headers, country, responseType, signal,
    } = request;

    let baseUrl;
    switch (service) {
      case 'preflight':
        baseUrl = process.env.REACT_APP_PREFLIGHT_URL;
        break;
      case 'prepress':
        baseUrl = process.env.REACT_APP_PREPRESS_URL;
        break;
      case 'customer':
        baseUrl = process.env.REACT_APP_CUSTOMER_URL;
        break;
      case 'user':
        baseUrl = process.env.REACT_APP_USER_URL;
        break;
      case 'ppc_service':
        baseUrl = process.env.REACT_APP_PPC_SERVICE_URL;
        break;
      case 'm2_ssh_service':
        baseUrl = process.env.REACT_APP_M2_SSH_SERVICE_URL;
        break;
      case 's3restore_service':
        baseUrl = process.env.REACT_APP_S3_RESTORE_URL;
        break;
      case 'item_state_service':
        baseUrl = process.env.REACT_APP_M2_ITEM_STATE_URL;
        break;
      case 'customer_complaint_service':
        baseUrl = process.env.REACT_APP_M2_CUSTOMER_COMPLAINT_URL;
        break;
      case 'shipping':
        baseUrl = process.env.REACT_APP_SHIPPING_URL;
        break;
      case 'marketplace':
        baseUrl = process.env.REACT_APP_MARKETPLACE_URL;
        break;
      case 'magento_no_gateway':
        baseUrl = process.env[`REACT_APP_GOGO_${country.toUpperCase()}_URL`];
        break;
      case 'configuration_service':
        baseUrl = process.env.REACT_APP_CONFIGURATION_SERVICE_URL;
        break;
      default:
      case 'magento':
        baseUrl = process.env.REACT_APP_MAGENTO_URL;
    }

    let axiosHeaders;
    if (headers === undefined) {
      axiosHeaders = {};
    } else {
      axiosHeaders = { ...headers };
    }
    if (service !== 'magento_no_gateway') {
      axiosHeaders.Authorization = token;
    }

    const axiosRequest = {
      method,
      url: `${baseUrl.replace(/\/+$/g, '')}/${path.replace(/^\/+/, '')}`,
      headers: axiosHeaders,
      params,
      responseType,
      signal,
      ...(['GET', 'DELETE'].includes(request.method.toUpperCase()) ? {} : { data }),
    };

    axios.interceptors.request.use((axiosRequestConfig) => {
      const newRequestConfig = { ...axiosRequestConfig };

      newRequestConfig.paramsSerializer = queryParams => stringify(
        queryParams,
        {
          arrayFormat: 'brackets',
          encode: false,
        },
      );

      return newRequestConfig;
    });

    return axios.request(axiosRequest);
  }

  /**
   * Request to backoffice with user Token and Service Url.
   * @param type
   * @param config
   * @returns {Promise<AxiosPromise<any>>}
   */
  static async request(type, config) {
    const token = await Auth.currentSession()
      .then(data => data.idToken.jwtToken);

    const apiRequest = {
      service: type,
      method: config.method,
      path: config.path,
      params: config.params,
      data: config.data,
      headers: config.headers,
      country: config.country,
      responseType: config.responseType,
      signal: config.signal,
    };

    return this.doRequest(apiRequest, token);
  }

  /**
   * Request to backoffice to download file
   * @param type
   * @param config
   * @returns {Promise<AxiosPromise<any>>}
   */
  static async download(type, config) {
    return this.request(type, { ...config, responseType: 'blob' });
  }

  static cleanFilter = (filter) => {
    const obj = Object.assign({}, filter);

    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') {
        obj[key] = BackOfficeUtils.cleanFilter(obj[key]);
      }

      if (obj[key] === undefined || obj[key] === null || obj[key].length === 0) {
        delete obj[key];
      }
    });
    return obj;
  };

  static updateRequestPagination = (request, page, perPage, callback) => {
    const newRequest = {
      ...request,
      pagination: {
        ...request.pagination,
        page: (request.pagination.perPage === perPage) ? page : 1,
        perPage,
      },
    };

    callback(newRequest);
  };

  static updateRequestSort = (request, field, order, callback) => {
    const newRequest = {
      ...request,
      order: {
        [field]: order,
      },
    };

    callback(newRequest);
  };

  static updateRequestFilter = (request, filter, callback) => {
    if (isEqual(request.filter, filter)) {
      return;
    }

    const newRequest = {
      ...request,
      filter,
    };

    callback(newRequest);
  };

  static reloadRequest = (request, callback) => {
    callback(request);
  };

  static updateRequestTable = (request, pagination, sort, callback) => {
    const newRequest = {
      ...request,
      pagination: {
        ...request.pagination,
        page: (request.pagination.perPage === pagination.perPage) ? pagination.page : 1,
        perPage: pagination.perPage,
      },
      order: sort,
    };

    callback(newRequest);
  }
};

export default BackOfficeUtils;
