import { apiAction } from 'lib/common/middleware/backOfficeApiMiddleware';
import { getCustomersRequest } from 'lib/Customer/services/customers';

export const RECEIVE_CUSTOMERS = 'RECEIVE_CUSTOMERS';
export const UPDATE_DASHBOARD_REQUEST = 'UPDATE_CUSTOMER_DASHBOARD_REQUEST';

function receiveCustomers(data) {
  return {
    type: RECEIVE_CUSTOMERS,
    customers: data['hydra:member'],
    total: data['hydra:totalItems'],
  };
}

export function fetchCustomers(request) {
  const { filter, pagination, order } = request;

  return apiAction(
    {
      request: getCustomersRequest(filter, pagination, order),
      onSuccess: receiveCustomers,
      label: 'customers',
    },
  );
}

export function updateRequest(request) {
  return {
    type: UPDATE_DASHBOARD_REQUEST,
    request,
  };
}
