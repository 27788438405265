import { apiAction } from 'lib/common/middleware/backOfficeApiMiddleware';
import { getOrderRequest, getOrderAggregateRequest } from 'lib/ItemState/services/order';

export const RECEIVE_ORDERS_RESPONSE = 'RECEIVE_ORDERS_RESPONSE';
export const RECEIVE_ORDER_ITEMS_RESPONSE = 'RECEIVE_ORDER_ITEMS_RESPONSE';
export const UPDATE_ORDERS_REQUEST = 'UPDATE_ORDERS_REQUEST';
export const RECEIVE_ERROR_ORDERS_RESPONSE = 'RECEIVE_ERROR_ORDERS_RESPONSE';

function receiveOrders(data) {
  return {
    type: RECEIVE_ORDERS_RESPONSE,
    orders: data['hydra:member'],
    total: data['hydra:totalItems'],
  };
}

function receiveOrdersError(error, newTabCode) {
  return {
    type: RECEIVE_ERROR_ORDERS_RESPONSE,
    error,
    newTabCode,
  };
}

function receiveOrderItems(newTabCode, order) {
  return (orderAggregate) => ({
    type: RECEIVE_ORDER_ITEMS_RESPONSE,
    orderAggregate,
    order,
    newTabCode,
  });
}

export function fetchOrders(request) {
  const { filter, pagination, order } = request;

  return apiAction(
    {
      request: getOrderRequest(filter, pagination, order),
      onSuccess: receiveOrders,
      label: 'orders',
    },
  );
}

export function fetchOrderItems(newTabCode, order) {
  const { id } = order;

  return apiAction(
    {
      request: getOrderAggregateRequest(id),
      onSuccess: receiveOrderItems(newTabCode, order),
      onFailure: (e) => receiveOrdersError(e, newTabCode),
      label: 'orderItems',
    },
  );
}

export function updateRequest(request) {
  return {
    type: UPDATE_ORDERS_REQUEST,
    request,
  };
}
