import {
  assignUserToLead,
  getActionRequest,
  getLeadComment,
  getLeadActions,
  updateLead,
  addLeadComment,
} from 'lib/Customer/services/leads';
import { apiAction } from '../../common/middleware/backOfficeApiMiddleware';

export const RECEIVE_ASSIGN_USER = 'RECEIVE_ASSIGN_USER';
export const REQUEST_ASSIGN_USER = 'REQUEST_ASSIGN_USER';
export const RECEIVE_LEAD_ACTIONS = 'RECEIVE_LEAD_ACTIONS';
export const REQUEST_LEAD_ACTIONS = 'REQUEST_LEAD_ACTIONS';
export const REQUEST_UPDATE_LEAD = 'REQUEST_UPDATE_LEAD';
export const RECEIVE_UPDATE_LEAD = 'RECEIVE_UPDATE_LEAD';
export const RECEIVE_LEAD = 'RECEIVE_LEAD';
export const RECEIVE_LEAD_COMMENT = 'RECEIVE_LEAD_COMMENT';
export const REQUEST_LEAD_COMMENT = 'REQUEST_LEAD_COMMENT';
export const REQUEST_SAVE_LEAD_COMMENT = 'REQUEST_SAVE_LEAD_COMMENT';
export const RECEIVE_SAVE_LEAD_COMMENT = 'RECEIVE_SAVE_LEAD_COMMENT';

function requestAssignUser() {
  return {
    type: REQUEST_ASSIGN_USER,
  };
}

function receiveAssignUser(lead) {
  return {
    type: RECEIVE_ASSIGN_USER,
    lead,
  };
}

function requestLeadActions() {
  return {
    type: REQUEST_LEAD_ACTIONS,
  };
}

function receiveLeadActions(actions) {
  return {
    type: RECEIVE_LEAD_ACTIONS,
    actions,
  };
}

function requestUpdateLead() {
  return {
    type: REQUEST_UPDATE_LEAD,
  };
}

function receiveUpdateLead(lead) {
  return {
    type: RECEIVE_UPDATE_LEAD,
    lead,
  };
}

export function assignUser(leadId, userId) {
  return (dispatch) => {
    dispatch(requestAssignUser());
    return assignUserToLead(leadId, userId)
      .then((lead) => dispatch(receiveAssignUser(lead)));
  };
}

export function fetchLeadActions(leadId) {
  return (dispatch) => {
    dispatch(requestLeadActions());
    return getLeadActions(leadId)
      .then((actions) => dispatch(receiveLeadActions(actions)));
  };
}

export function updateLeadData(leadId, data) {
  return (dispatch) => {
    dispatch(requestUpdateLead());
    return updateLead(leadId, data)
      .then((lead) => dispatch(receiveUpdateLead(lead)));
  };
}

// Using Middleware
function receiveLead(lead) {
  return {
    type: RECEIVE_LEAD,
    lead,
  };
}

function successLeadAction(lead) {
  return (dispatch) => {
    dispatch(receiveLead(lead));
    dispatch(fetchLeadActions(lead.id));
  };
}

export function performLeadAction(leadId, action) {
  return apiAction({
    request: getActionRequest(leadId, action),
    onSuccess: successLeadAction,
    label: 'lead_action',
  });
}

function receiveLeadComment(comments) {
  return {
    type: RECEIVE_LEAD_COMMENT,
    comments,
  };
}

function requestLeadComment() {
  return {
    type: REQUEST_LEAD_COMMENT,
  };
}
function requestSaveLeadComment() {
  return {
    type: REQUEST_SAVE_LEAD_COMMENT,
  };
}

export function fetchLeadComment(leadId) {
  return (dispatch) => {
    dispatch(requestLeadComment());
    return getLeadComment(leadId)
      .then((actions) => dispatch(receiveLeadComment(actions)));
  };
}

function receiveSaveLeadComment(leadId, comment) {
  return (dispatch) => {
    dispatch(fetchLeadComment(leadId));
    return {
      type: RECEIVE_SAVE_LEAD_COMMENT,
      leadId,
      comment,
    };
  };
}

export function addLeadCommentData(leadId, comment) {
  return (dispatch) => {
    dispatch(requestSaveLeadComment());
    return addLeadComment(comment)
      .then((data) => dispatch(receiveSaveLeadComment(leadId, data)));
  };
}
