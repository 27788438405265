export const ADD_TAB_HEAD = 'ADD_TAB_HEAD';
export const REMOVE_TAB_HEAD = 'REMOVE_TAB_HEAD';
export const ADD_TAB_DATA = 'ADD_TAB_DATA';
export const UPDATE_ACTIVE_ORDER = 'UPDATE_ACTIVE_ORDER';
export const UPDATE_ACTIVE_TAB = 'UPDATE_ACTIVE_TAB';
export const UPDATE_ITEMS_DATA = 'UPDATE_ITEMS_DATA';
export const UPDATE_ITEMS_DATA_FILTER = 'UPDATE_ITEMS_DATA_FILTER';
export const UPDATE_ORDERS_DATA = 'UPDATE_ORDERS_DATA';
export const UPDATE_ITEM_MODAL_DATA = 'UPDATE_ITEM_MODAL_DATA';
export const UPDATE_DATA_FILTER = 'UPDATE_DATA_FILTER';
export const UPDATE_ORDERS_DATA_FILTER = 'UPDATE_ORDERS_DATA_FILTER';
export const RESET_ITEMS_DATA_FILTER = 'RESET_ITEMS_DATA_FILTER';
export const RESET_VI_DATA_FILTER = 'RESET_VI_DATA_FILTER';
export const RESET_PROOF_DATA_FILTER = 'RESET_PROOF_DATA_FILTER';
export const RESET_PROBLEM_DATA_FILTER = 'RESET_PROBLEM_DATA_FILTER';
export const RESET_RUSHJOBS_DATA_FILTER = 'RESET_RUSHJOBS_DATA_FILTER';
export const RESET_NEWARTWORK_DATA_FILTER = 'RESET_NEWARTWORK_DATA_FILTER';
export const RESET_DESIGN_DATA_FILTER = 'RESET_DESIGN_DATA_FILTER';
export const RESET_PROMO_DATA_FILTER = 'RESET_PROMO_DATA_FILTER';
export const RESET_PREPRESS_DATA_FILTER = 'RESET_PREPRESS_DATA_FILTER';
