import {
  ACTIVATE_TAB,
  ADD_TAB,
  REMOVE_TAB,
} from 'lib/ItemState/actions/tab';
import {
  RECEIVE_ORDER_ITEMS_RESPONSE,
  RECEIVE_ERROR_ORDERS_RESPONSE,
} from 'lib/ItemState/actions/order';

const initialState = {
  activeTab: '',
  tabItems: {},
};

function tabReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TAB:
      return {
        ...state,
        tabItems: {
          ...state.tabItems,
          [action.tabCode]: {
            label: action.tabLabel,
            props: action.tabProps ?? {},
          },
        },
      };
    case REMOVE_TAB: {
      const { [action.tabCode]: oldLabel, ...tabItems } = state.tabItems;
      return { ...state, tabItems };
    }
    case ACTIVATE_TAB:
      return {
        ...state,
        activeTab: action.tabCode,
      };
    case RECEIVE_ORDER_ITEMS_RESPONSE:
      return {
        ...state,
        tabItems: {
          ...state.tabItems,
          [action.newTabCode]: {
            label: action.orderAggregate.incrementId,
            props: {
              orderAggregate: action.orderAggregate,
              orderId: action.order.id,
            },
          },
        },
      };
      case RECEIVE_ERROR_ORDERS_RESPONSE:
        return {
          ...state,
          tabItems: {
            ...state.tabItems,
            [action.newTabCode]: {
              label: 'Error',
              props: {
                error: action.error,
              },
            },
          },
        };
    default:
      return state;
  }
}

export default tabReducer;
