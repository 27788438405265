import {
  RECEIVE_ALL_SENT_JOB_RESPONSE,
  UPDATE_ALL_SENT_JOB_REQUEST,
} from 'lib/Production/actions/sentjob';
import { API_END, API_START } from 'lib/common/actions/types';

const initialState = {
  sentJob: {
    items: [],
    filter: [],
    order: { jobCreatedAt: 'DESC' },
    pagination: {
      page: 1,
      perPage: 30,
    },
    total: 0,
    loading: false,
  },
};

function sentJob(state = initialState, action = null) {
  switch (action.type) {
    case API_START:
    case API_END:
      if (action.payload.startsWith('sentjob')) {
        return {
          ...state,
          sentJob: { ...state.sentJob, loading: action.type === API_START },
        };
      }
      break;
    case UPDATE_ALL_SENT_JOB_REQUEST:
      return {
        ...state,
        sentJob: {
          ...state.sentJob,
          filter: action.filter,
          pagination: action.pagination,
          order: action.order,
        },
      };
    case RECEIVE_ALL_SENT_JOB_RESPONSE:
      return {
        ...state,
        sentJob: {
          ...state.sentJob,
          items: action.items,
          total: action.total,
        },
      };
    default:
      return state;
  }

  return state;
}

export default sentJob;
