import { apiAction } from 'lib/common/middleware/backOfficeApiMiddleware';
import { getShippingOrderRequest } from 'lib/Shipping/services/shipping';

export const RECEIVE_SHIPPING_ORDERS_RESPONSE = 'RECEIVE_SHIPPING_ORDERS_RESPONSE';
export const UPDATE_SHIPPING_ORDERS_REQUEST = 'UPDATE_SHIPPING_ORDERS_REQUEST';

const receiveAllItems = (country) => (data) => ({
  type: RECEIVE_SHIPPING_ORDERS_RESPONSE,
  country,
  items: data['hydra:member'],
  total: data['hydra:totalItems'],
});

export function fetchShippingOrders(request) {
  const {
    filter, pagination, order, country,
  } = request;
  return apiAction(
    {
      request: getShippingOrderRequest({
        ...filter,
        country,
      }, pagination, order),
      onSuccess: receiveAllItems(country),
      label: `outbound.${country}`,
    },
  );
}

export function updateShippingOrdersRequest(request) {
  return {
    ...request,
    type: UPDATE_SHIPPING_ORDERS_REQUEST,
  };
}
