import {
  ADD_TAB_DATA,
  ADD_TAB_HEAD,
  REMOVE_TAB_HEAD,
  UPDATE_ACTIVE_ORDER,
  UPDATE_ACTIVE_TAB,
  UPDATE_ITEM_MODAL_DATA,
  UPDATE_ITEMS_DATA,
  UPDATE_DATA_FILTER,
  UPDATE_ORDERS_DATA_FILTER,
  UPDATE_ORDERS_DATA,
  RESET_ITEMS_DATA_FILTER,
  RESET_VI_DATA_FILTER,
  RESET_PROOF_DATA_FILTER,
  RESET_PROBLEM_DATA_FILTER,
  RESET_RUSHJOBS_DATA_FILTER,
  RESET_NEWARTWORK_DATA_FILTER,
  RESET_DESIGN_DATA_FILTER,
  RESET_PROMO_DATA_FILTER,
  RESET_PREPRESS_DATA_FILTER,
} from '../constants/action-types';

const defaultFilterTemplate = {
  'flatOrder.orderNumber': '',
  'flatOrder.salePerson': '',
  itemId: '',
  country: [],
  product: '',
  'flatOrder.storeOrderStatus': [
    'processing',
    'pending',
    'credit_approved',
    'credit_forapproval',
    'holded_coupon_issued',
    'credit',
  ],
};

const defaultFilterTemplatePP = {
  'flatOrder.orderNumber': '',
  'flatOrder.salePerson': '',
  itemId: '',
  country: [],
  product: '',
  'flatOrder.storeOrderStatus': [
    'processing',
    'pending',
    'credit_approved',
    'credit_forapproval',
    'holded_coupon_issued',
    'credit',
    'completed',
    'holded',
  ],
};

const defaultItemFilter = {
  ...defaultFilterTemplatePP,
  processType: '',
  status: [
  ],
  flags: [],
  'deadlineArtwork[after]': null,
  'deadlineArtwork[before]': null,
  'lockedAt[before]': { toString() { return new Date().toISOString(); } },
  page: 1,
  'order[updatedAt]': 'desc',
};

const defaultViFilter = {
  ...defaultFilterTemplate,
  assignedUser: [],
  status: [
    'ready_for_prepress',
  ],
  flags: [],
  'lockedAt[before]': { toString() { return new Date().toISOString(); } },
  'deadlineArtwork[after]': null,
  'deadlineArtwork[before]': null,
  processType: '',
  page: 1,
  'order[deadlineArtwork]': 'asc',
  after: null,
  before: null,
};

const defaultProofFilter = {
  ...defaultFilterTemplate,
  processType: '',
  status: [
    'proof_confirmation',
    'wait_for_feedback',
    'wait_for_new_artwork',
  ],
  flags: [],
  'deadlineArtwork[after]': null,
  'deadlineArtwork[before]': null,
  page: 1,
  'order[deadlineArtwork]': 'asc',
  after: null,
  before: null,
};

const defaultDesignServiceFilter = {
  ...defaultFilterTemplate,
  assignedUser: [],
  status: [
    'ready_for_design', 'design_work_in_progress', 'waiting_questionnaire_answer',
  ],
  isRush: false,
  'deadlineArtwork[after]': null,
  'deadlineArtwork[before]': null,
  page: 1,
  'order[updatedAt]': 'desc',
  after: null,
  before: null,
};

const defaultRushJobsFilter = {
  ...defaultFilterTemplate,
  assignedUser: [],
  status: [
    'ready_for_prepress',
  ],
  isRush: true,
  'deadlineArtwork[after]': null,
  'deadlineArtwork[before]': null,
  'lockedAt[before]': { toString() { return new Date().toISOString(); } },
  page: 1,
  'order[deadlineArtwork]': 'asc',
  after: null,
  before: null,
};

const defaultNewArtworksFilter = {
  ...defaultFilterTemplate,
  assignedUser: [],
  status: [
    'wait_for_new_artwork',
    'waiting_for_artwork',
  ],
  'deadlineArtwork[after]': null,
  'deadlineArtwork[before]': null,
  page: 1,
  'order[deadlineArtwork]': 'desc',
  after: null,
  before: null,
};

const defaultProblemJobsFilter = {
  ...defaultFilterTemplate,
  processType: '',
  status: [
    'fetching_artwork',
    'uploading_artwork',
    'requesting_new_artwork',
    'creating_onlineproof',
    'sending_to_production',
    'checking_out_artwork',
    'checking_in_artwork',
    'processing_action',
  ],
  flags: [],
  'deadlineArtwork[after]': null,
  'deadlineArtwork[before]': null,
  page: 1,
  'order[deadlineArtwork]': 'asc',
  after: null,
  before: null,
};

const defaultPromoFilter = {
  ...defaultFilterTemplate,
  assignedUser: [],
  status: [
    'ready_for_promo', 'promo_work_in_progress', 'wait_promo_feedback',
  ],
  isRush: false,
  'deadlineArtwork[after]': null,
  'deadlineArtwork[before]': null,
  page: 1,
  'order[deadlineArtwork]': 'asc',
  after: null,
  before: null,
};

const defaultPrepressFilter = {
  assignedUser: [],
  status: [
  ],
  isRush: false,
  'deadlineArtwork[after]': null,
  'deadlineArtwork[before]': null,
  page: 1,
  'order[deadlineArtwork]': 'asc',
  after: null,
  before: null,
};

export const initialState = {
  activeTab: 'items',
  activeTabData: {},
  activeOrder: '',
  existedTabHead: {},
  existedTabData: {},
  itemModalData: null,
  itemsData: {
    filter: {
      ...defaultItemFilter,
    },
  },
  ordersData: {
    filter: {
      'order[orderDate]': 'desc',
      page: 1,
      after: null,
      before: null,
    },
  },
  viData: {
    filter: {
      ...defaultViFilter,
    },
  },
  proofData: {
    filter: {
      ...defaultProofFilter,
    },
  },
  problemData: {
    filter: {
      ...defaultProblemJobsFilter,
    },
  },
  rushJobsData: {
    filter: {
      ...defaultRushJobsFilter,
    },
  },
  newArtworkData: {
    filter: {
      ...defaultNewArtworksFilter,
    },
  },
  designServiceData: {
    filter: {
      ...defaultDesignServiceFilter,
    },
  },
  promoData: {
    filter: {
      ...defaultPromoFilter,
    },
  },
  prepressData: {
    filter: {
      ...defaultPrepressFilter,
    },
  },
};

export default (state = initialState, action) => {
  // previous state
  const tabData = state.existedTabData;
  const tabHead = state.existedTabHead;
  const { [action.data]: value, ...withoutData } = tabHead;

  switch (action.type) {
    case UPDATE_ACTIVE_ORDER:
      return {
        ...state,
        activeOrder: action.activeOrder,
      };
    case UPDATE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };
    case ADD_TAB_DATA:
      tabData[action.data.index] = action.data.data;
      return {
        ...state,
        existedTabData: tabData,
      };
    case ADD_TAB_HEAD:
      return {
        ...state,
        existedTabHead: {
          ...state.existedTabHead,
          [action.data.id]: action.data,
        },
      };
    case REMOVE_TAB_HEAD:
      // data is a index when call to remove.
      return {
        ...state,
        existedTabHead: withoutData,
      };

    case UPDATE_ITEMS_DATA:
      return {
        ...state,
        itemsData: action.data,
      };

    case UPDATE_DATA_FILTER:
      return {
        ...state,
        [action.data.dashboard]: {
          ...state.itemsData,
          filter: { ...state[action.data.dashboard].filter, [action.data.key]: action.data.value },
        },
      };

    case RESET_ITEMS_DATA_FILTER:
      return {
        ...state,
        itemsData: {
          ...state.itemsData,
          filter: {
            ...defaultItemFilter,
          },
        },
      };

    case RESET_VI_DATA_FILTER:
      return {
        ...state,
        viData: {
          ...state.viData,
          filter: {
            ...defaultViFilter,
          },
        },
      };

    case RESET_RUSHJOBS_DATA_FILTER:
      return {
        ...state,
        rushJobsData: {
          ...state.rushJobsData,
          filter: { ...defaultRushJobsFilter },
        },
      };

    case RESET_NEWARTWORK_DATA_FILTER:
      return {
        ...state,
        rushJobsData: {
          ...state.rushJobsData,
          filter: { ...defaultNewArtworksFilter },
        },
      };

    case RESET_DESIGN_DATA_FILTER:
      return {
        ...state,
        designServiceData: {
          ...state.designServiceData,
          filter: {
            ...defaultDesignServiceFilter,
          },
        },
      };

    case RESET_PROOF_DATA_FILTER:
      return {
        ...state,
        proofData: {
          ...state.proofData,
          filter: {
            ...defaultProofFilter,
          },
        },
      };

    case RESET_PROBLEM_DATA_FILTER:
      return {
        ...state,
        problemData: {
          ...state.problemData,
          filter: {
            ...defaultProblemJobsFilter,
          },
        },
      };
    case RESET_PROMO_DATA_FILTER:
      return {
        ...state,
        promoData: {
          ...state.promoData,
          filter: {
            ...defaultPromoFilter,
          },
        },
      };
    case RESET_PREPRESS_DATA_FILTER:
      return {
        ...state,
        prepressData: {
          ...state.prepressData,
          filter: {
            ...defaultPrepressFilter,
          },
        },
      };
    case UPDATE_ORDERS_DATA:
      return {
        ...state,
        ordersData: action.data,
      };

    case UPDATE_ORDERS_DATA_FILTER:
      return {
        ...state,
        ordersData: {
          ...state.ordersData,
          filter: action.data,
        },
      };

    case UPDATE_ITEM_MODAL_DATA:
      return {
        ...state,
        itemModalData: action.data,
      };

    default:
      return state;
  }
};
