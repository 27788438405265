export default {
  statuses: [
    { key: 'complete_error', value: 'Error' },
    { key: 'complete_success', value: 'Success' },
    { key: 'complete_warning', value: 'Warning' },
  ],
  types: [
    { key: 'flat', value: 'Flat' },
  ],
};
