import {
  UPDATE_FILTER,
  UPDATE_PREFLIGHT_RECORDS,
} from '../constants/action-types';
import { defaultFilter } from '../services/PreflightService';

const initialState = {
  preflightRecords: {},
  activeFilter: defaultFilter(),
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PREFLIGHT_RECORDS:
      return { ...state, preflightRecords: action.payload };
    case UPDATE_FILTER:
      return { ...state, activeFilter: action.payload };
    default:
      return state;
  }
}

export default rootReducer;
