import {
  OPEN_CREATE_SSH_TASK_MODAL,
  RECEIVE_SSH_TASKS,
  CLOSE_CREATE_SSH_TASK_MODAL,
  UPDATE_CREATE_SSH_TASK_MODAL,
  GET_SSH_TASKS,
  TOGGLE_AUTO_RELOAD,
} from 'lib/M2SSHService/actions';

const initialState = {
  dashboard: {
    modal: {
      isOpen: false,
      publicKey: localStorage.getItem('sshTaskService/publicKey'),
      env: 'm2-staging',
      expireAfter: 30,
    },
    items: [],
    filter: {},
    order: { createdAt: 'desc' },
    pagination: {
      page: 1,
      perPage: 10,
    },
    total: 0,
    loading: false,
    autoReload: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SSH_TASKS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loading: true,
        },
      };
    case RECEIVE_SSH_TASKS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          total: action.total,
          items: action.items,
          loading: false,
        },
      };
    case OPEN_CREATE_SSH_TASK_MODAL:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          modal: {
            ...state.dashboard.modal,
            isOpen: true,
          },
        },
      };
    case CLOSE_CREATE_SSH_TASK_MODAL:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          modal: {
            ...state.dashboard.modal,
            isOpen: false,
          },
        },
      };
    case UPDATE_CREATE_SSH_TASK_MODAL:
      localStorage.setItem('sshTaskService/publicKey', action.data.publicKey);
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          modal: {
            ...action.data,
          },
        },
      };
    case TOGGLE_AUTO_RELOAD:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          autoReload: !state.dashboard.autoReload,
        },
      };
    default:
      return state;
  }
};
