import moment from 'moment/moment';
import { getStoreCountryById } from 'lib/config/countries';

export const dateTimeUtcToLocalFormatter = (data) => (data ? moment.utc(data).local().format('YYYY-MM-DD HH:mm') : null);
export const dateTimeFormatter = (data) => (data ? moment(data).format('YYYY-MM-DD HH:mm') : null);
export const dateFormatter = (data) => (data ? moment(data).format('YYYY-MM-DD') : null);
export const dateFormatterWithDay = (data) => (data ? moment(data).format('ddd YYYY-MM-DD') : null);
export const readableDateFormatter = (data) => (data ? moment(data).format('D MMMM Y') : null);
export const readableShortDateFormatter = (data) => (data ? moment(data).format('D MMM Y') : null);
export const dateTimeFormatterFully = (data) => (data ? moment(data).format('D MMMM Y HH:mm') : null);
export const dateTimeUtcToLocalFormatterFully = (data) => (data ? moment.utc(data).local().format('D MMM Y, HH:mm:ss') : null);

export const storeFormatter = (countryCode) => {
  const country = getStoreCountryById(countryCode);

  if (country) {
    return country.label;
  }

  return '';
};
export const numberFormatter = (number) => ((number || number === 0) ? new Intl.NumberFormat(
  'en',
  {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
).format(number) : null);

export const qtyFormatter = (number) => ((number || number === 0) ? new Intl.NumberFormat(
  'en',
  {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
).format(number) : null);

export const userFormatter = (userList, userId) => {
  const found = Object.values(userList).find((key) => key.id === parseInt(userId, 10));
  if (found) {
    return `${found.firstName}`;
  }
  return userId;
};

export const currencyFormatter = (amount, currency, asString = false) => {
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency ?? 'THB',
    currencyDisplay: 'code',
    currencySign: 'accounting',
  });

  if (asString) {
    return formatter.format(amount ?? 0);
  }

  const format = formatter.formatToParts(amount ?? 0)
    .reduce((formatted, { type, value }) => {
      switch (type) {
        case 'currency': return {
          ...formatted,
          currency: value,
        };
        default: return {
          ...formatted,
          amount: formatted.amount + value,
        };
      }
    }, {
      amount: '',
      currency: '',
    });

  return format;
};

export const humanFileSize = (size) => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / 1024 ** i).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
};

export const humanBandwidth = (size) => {
  const i = Math.floor(Math.log(size) / Math.log(1000));
  return `${(size / 1000 ** i).toFixed(2) * 1} ${[
    ' B/s',
    ' kB/s',
    ' MB/s',
    ' GB/s',
    ' TB/s',
  ][i]}`;
};

const rtf = new Intl.RelativeTimeFormat(undefined, { numeric: 'auto' });

export const getRelativeTime = (d1, d2 = new Date()) => {
  const units = {
    year: 24 * 60 * 60 * 1000 * 365,
    month: 24 * 60 * 60 * 1000 * 365 / 12,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
    second: 1000,
  };
  const elapsed = d1 - d2;

  // eslint-disable-next-line no-restricted-syntax
  for (const u in units) {
    if (Math.abs(elapsed) > units[u] || u === 'second') return rtf.format(Math.round(elapsed / units[u]), u);
  }
  return undefined;
};
