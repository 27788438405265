export default {
  statuses: [
    { key: 'new', value: 'New' },
    { key: 'in_progress', value: 'In Progress' },
    { key: 'closed', value: 'Closed' },
    { key: 'canceled', value: 'Canceled' },
  ],
  leadsSearchOptions: [
    { key: 'leadNumber', value: 'Lead Number' },
    { key: 'productName', value: 'Product' },
    { key: 'company', value: 'Company' },
  ],

  leadSources: [
    { key: '', value: 'Unknown' },
    { key: 'Promo Quotation', value: 'Promo Quotation' },
  ],

  organizationsSearchOptions: [
    { key: 'name', value: 'Name' },
    { key: 'referenceNumber', value: 'Reference' },
    { key: 'registrationId', value: 'Reg/Tax ID' },
  ],
  organizationGroupTypes: [
    { key: 'group', value: 'Group' },
    { key: 'individual', value: 'Individual' },
  ],
  organizationTiers: [
    { key: 'general', value: 'General' },
    // { key: 'member', value: 'Member' },
    { key: 'gold_no_benefit', value: 'Gold (No Benefit)' },
    { key: 'gold', value: 'Gold' },
    { key: 'diamond', value: 'Diamond' },
    { key: 'platinum', value: 'Platinum' },
  ],
  organizationTypes: [
    { key: 'personal', value: 'Personal' },
    { key: 'freelance', value: 'Freelance' },
    { key: 'public_sector_education_non_profit', value: 'Public, Edu, Non Profit' },
    { key: 'printer_reseller', value: 'Printer/Reseller' },
    { key: 'designer_agency', value: 'Design Agency' },
    { key: 'business', value: 'Business' },
  ],
  organizationSizes: [
    { key: 'small', value: '< 10' },
    { key: 'medium', value: '10-100' },
    { key: 'large', value: '100-500' },
    { key: 'corporate', value: '> 500' },
  ],
  organizationIndustries: [
    { key: 'agriculture_fish', value: 'Agriculture and Fishing' },
    { key: 'mining', value: 'Mining and Quarrying' },
    { key: 'manufacturing', value: 'Manufacturing' },
    { key: 'energy_aircon', value: 'Electricity, Gas, Steam and Air-Conditioning Supply' },
    { key: 'water', value: 'Water Supply; Wastewater, Waste Management and Related Activities' },
    { key: 'construction', value: 'Construction' },
    { key: 'trade', value: 'Wholesale and Retail Trade; Repair of motor vehicles' },
    { key: 'logistics_storage', value: 'Transportation and Storage' },
    { key: 'hospitality', value: 'Accommodation and Food Service Activities' },
    { key: 'info_comms', value: 'Information and Communications' },
    { key: 'financial', value: 'Financial and Insurance Activities' },
    { key: 'real_estate', value: 'Real Estate Activities' },
    { key: 'sci_tech', value: 'Professional, Scientific and Technical Activities' },
    { key: 'admin_support', value: 'Administrative and Support Service Activities' },
    { key: 'public_admin_defence', value: 'Public Administration and Defence' },
    { key: 'education', value: 'Education' },
    { key: 'health_social', value: 'Health and Social Services' },
    { key: 'arts_ents_rec', value: 'Arts, Entertainment and Recreation' },
    { key: 'other_services', value: 'Other Service Activities' },
    { key: 'household', value: 'Activities of Households as Employers of Domestic Personnel' },
    { key: 'foreign_org', value: 'Activities of Extra-Territorial Organisations and Bodies' },
    { key: 'other', value: 'Others' },
  ],
  organizationStatus: [
    { key: 'unverified', value: 'Unverified' },
    { key: 'new', value: 'New' },
    { key: 'active', value: 'Active' },
    { key: 'inactive', value: 'Inactive' },
    { key: 'out_of_business', value: 'Out of Business' },
  ],
};
