const API_QUERY = 'query';
const API_CREATE = 'create';

export const getSSHTask = () => ({
  service: 'm2_ssh_service',
  method: 'GET',
  path: API_QUERY,
  params: {},
});

export const createSSHTask = (env, publicKey, expireAfter) => ({
  service: 'm2_ssh_service',
  method: 'POST',
  path: API_CREATE,
  data: {
    env,
    publicKey,
    expireAfter,
  },
});
