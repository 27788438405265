export const ORG_ADD_TAB_HEAD = 'ORG_ADD_TAB_HEAD';
export const ORG_REMOVE_TAB_HEAD = 'ORG_REMOVE_TAB_HEAD';
export const ORG_ADD_TAB_DATA = 'ORG_ADD_TAB_DATA';
export const ORG_UPDATE_ACTIVE_TAB = 'ORG_UPDATE_ACTIVE_TAB';

export const UPDATE_ORGANIZATIONS_DATA = 'UPDATE_ORGANIZATIONS_DATA';
export const UPDATE_ORGANIZATIONS_DATA_FILTER = 'UPDATE_ORGANIZATIONS_DATA_FILTER';
export const RESET_ORGANIZATIONS_DATA_FILTER = 'RESET_ORGANIZATIONS_DATA_FILTER';

export const RECEIVE_USER_DETAIL = 'RECEIVE_USER_DETAIL';
