import {
  RECEIVE_COMPLAINTS_RESPONSE,
  UPDATE_COMPLAINTS_REQUEST,
} from 'lib/CustomerComplaint/actions/complaint';
import { API_END, API_START } from 'lib/common/actions/types';
import { getComplaintDefaultFilter } from 'lib/CustomerComplaint/services/complaint';

const initialState = {
  complaints: {
    items: [],
    filter: getComplaintDefaultFilter(),
    order: { createdAt: 'DESC' },
    pagination: {
      page: 1,
      perPage: 30,
    },
    total: 0,
    loading: false,
  },
};

function complaints(state = initialState, action) {
  switch (action.type) {
    case API_START:
      if (action.payload === 'complaints') {
        return { ...state, complaints: { ...state.complaints, loading: true } };
      }
      break;
    case API_END:
      if (action.payload === 'complaints') {
        return { ...state, complaints: { ...state.complaints, loading: false } };
      }
      break;
    case RECEIVE_COMPLAINTS_RESPONSE:
      return {
        ...state,
        complaints: {
          ...state.complaints,
          items: action.complaints,
          total: action.total,
        },
      };
    case UPDATE_COMPLAINTS_REQUEST:
      return {
        ...state,
        complaints: {
          ...state.complaints,
          filter: action.request.filter,
          pagination: action.request.pagination,
          order: action.request.order,
        },
      };
    default:
      return state;
  }

  return state;
}

export default complaints;
