import {
  getDefaultSalesFilter,
} from 'lib/Sales/services/sales';
import {
  RECEIVE_SALES,
  UPDATE_DASHBOARD_REQUEST,
  RECEIVE_SALES_ACTIONS,
  REQUEST_SALES_ACTIONS,
} from 'lib/Sales/actions/sales';

import { API_END, API_START } from 'lib/common/actions/types';

const initialState = {
  salesStaffs: [],
  dashboard: {
    sales: [],
    filter: getDefaultSalesFilter(),
    order: { createdAt: 'desc' },
    pagination: {
      page: 1,
      perPage: 10,
    },
    total: 0,
    loading: false,
    salesActions: [],
    isFetchingActions: false,
  },
};

function sales(state = initialState, action) {
  switch (action.type) {
    case API_START:
      if (action.payload === 'sales') {
        return {
          ...state,
          dashboard: { ...state.dashboard, loading: true },
        };
      }
      break;
    case API_END:
      if (action.payload === 'sales') {
        return {
          ...state,
          dashboard: { ...state.dashboard, loading: false },
        };
      }
      break;
    case RECEIVE_SALES:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          sales: action.sales,
          total: action.total,
        },
      };
    case UPDATE_DASHBOARD_REQUEST:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          filter: action.request.filter,
          pagination: action.request.pagination,
          order: action.request.order,
        },
      };
    case REQUEST_SALES_ACTIONS:
      return { ...state, isFetchingActions: true };
    case RECEIVE_SALES_ACTIONS:
      return {
        ...state,
        isFetchingActions: false,
        salesActions: action.actions,
      };
    default:
      return state;
  }

  return state;
}

export default sales;
