import { combineReducers } from 'redux';
import inboundView from './inbound';
import outboundView from './outbound';
import outboundByJobView from './outbound-by-jobs';
import shipmentView from './shipment';

export default combineReducers({
  inboundView,
  outboundView,
  outboundByJobView,
  shipmentView,
});
