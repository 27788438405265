import complaintConfig from 'lib/CustomerComplaint/config';
import BackOfficeUtils from 'lib/BackOfficeUtils';

const API_QUERY_COMPLAINT = 'api/complaint';
const API_QUERY_COMPLAINT_COMMENT = 'api/complaint-comment';
const API_QUERY_COMPLAINT_COMMENTS = 'api/complaint/{id}/comments';
const API_QUERY_COMPLAINT_COMMENT_VERSIONS = 'api​/complaint-comment/{id}/verions';

const getMarketplaceJobCache = {};

export const getMarketplaceJob = async (params, useCache = true) => {
  const cacheKey = JSON.stringify(params);
  if (useCache) {
    if (getMarketplaceJobCache[cacheKey] !== undefined) {
      return getMarketplaceJobCache[cacheKey];
    }
  }
  getMarketplaceJobCache[cacheKey] = await BackOfficeUtils.request('marketplace', {
    method: 'GET',
    path: 'v1/production/list',
    params,
  });

  return getMarketplaceJobCache[cacheKey];
};

export const mapStoreCodeMarketplaceStore = (storeCode) => {
  if (storeCode.indexOf('th') !== -1) {
    return 'th';
  }
  return 'my';
};

export const mapMarketplaceStoreToStoreCode = (marketplaceWebsiteCode) => {
  if (marketplaceWebsiteCode.indexOf('th') !== -1) {
    return 'th_';
  }
  return ['my_', 'sg_'];
};

export const getComplaintDefaultFilter = () => ({
  incrementId: '',
  complaintId: '',
  itemId: '',
  store: [],
  status: ['Open'],
  assignTo: undefined,
  jobNumber: '',
});

export const getComplaintRequest = (filter, pagination, order) => {
  const params = {
    _page: pagination.page,
    _perPage: pagination.perPage,
    _order: order,
  };

  if (filter.complaintId) {
    params.incrementId = filter.complaintId;
  }

  if (filter.incrementId) {
    params['orderLink.orderIncrementId'] = filter.incrementId;
  }

  if (filter.store) {
    params['orderLink.storeCode'] = filter.store;
  }

  if (filter.itemId) {
    params['orderLinkItems.itemId'] = [filter.itemId];
  }

  if (filter.status) {
    params.status = filter.status;
  }

  if (filter.assignTo) {
    params.assignTo = filter.assignTo;
  }

  if (filter['createdAt[after]']) {
    params['createdAt[after]'] = filter['createdAt[after]'];
  }

  if (filter['createdAt[before]']) {
    params['createdAt[before]'] = filter['createdAt[before]'];
  }

  return async () => {
    if (filter.jobNumber) {
      const jobs = await getMarketplaceJob({
        jobNumber: filter.jobNumber,
      });
      if (jobs.data.length > 0) {
        const [firstJob] = jobs.data;
        params['orderLinkItems.itemId'] = firstJob.orderItems.map((item) => item.magentoItemId);
        params['orderLink.storeCode'] = mapMarketplaceStoreToStoreCode(firstJob.website);
      }
    }

    return {
      service: 'customer_complaint_service',
      method: 'GET',
      path: API_QUERY_COMPLAINT,
      params,
    };
  };
};

export const getComplaintCommentRequest = (complaintId, createdAtBefore = null) => {
  const params = {
    _page: 1,
    _perPage: 5,
    _order: {
      createdAt: 'DESC',
    },
  };

  if (createdAtBefore !== null) {
    params['createdAt[strictly_before]'] = encodeURIComponent(createdAtBefore);
  }

  const id = encodeURIComponent(complaintId);

  return {
    service: 'customer_complaint_service',
    method: 'GET',
    path: API_QUERY_COMPLAINT_COMMENTS.replace('{id}', id),
    params,
  };
};

export const getSelectOptions = (data) => data.reduce(
  (options, option) => {
    options.push({ value: option.key, label: option.value });
    return options;
  },
  [],
);

export const getStoreSelect = () => {
  const { stores } = complaintConfig;

  return getSelectOptions(stores);
};

export const getComplaintStatusSelect = () => {
  const { ticketStatus } = complaintConfig;

  return getSelectOptions(ticketStatus.map((status) => ({
    key: status.label,
    value: status.value,
  })));
};

export const getComplaintCreateRequest = (data) => ({
  service: 'customer_complaint_service', method: 'POST', path: API_QUERY_COMPLAINT, data,
});

export const getComplaintUpdateRequest = (complaintId, data) => ({
  service: 'customer_complaint_service',
  method: 'PATCH',
  path: `${API_QUERY_COMPLAINT}/${complaintId}`,
  data,
  headers: {
    'content-type': 'application/merge-patch+json',
  },
});

export const getComplaintCommentUpdateRequest = (commentId, data) => ({
  service: 'customer_complaint_service',
  method: 'PATCH',
  path: `${API_QUERY_COMPLAINT_COMMENT}/${commentId}`,
  data,
  headers: {
    'content-type': 'application/merge-patch+json',
  },
});

export const getComplaintCommentVersionRequest = (commentId, createdAtBefore = null) => {
  const params = {
    _page: 1,
    _perPage: 25,
    _order: {
      createdAt: 'DESC',
    },
  };

  if (createdAtBefore !== null) {
    params['createdAt[strictly_before]'] = encodeURIComponent(createdAtBefore);
  }

  const id = encodeURIComponent(commentId);

  return {
    service: 'customer_complaint_service',
    method: 'GET',
    path: API_QUERY_COMPLAINT_COMMENT_VERSIONS.replace('{id}', id),
    params,
  };
};

export const getUploadPresignUrl = (uuid, filename, fileType) => BackOfficeUtils.request('customer_complaint_service', {
  method: 'get',
  path: `api/presign/put/${encodeURIComponent(uuid)}?filename=${encodeURIComponent(filename)}&filetype=${encodeURIComponent(fileType)}`,
}).then((res) => res.data.presignPutUrl);

export const getOrder = (orderIncrementId) => BackOfficeUtils.request('customer_complaint_service', {
  method: 'get',
  path: 'api/order',
  params: {
    id: orderIncrementId,
  },
}).then((res) => res.data['hydra:member']);

export const getComplaintHistory = (complaintId, _page = 1, _statusExist = null, _reverseSort = false) => BackOfficeUtils.request('customer_complaint_service', {
  method: 'get',
  path: 'api/log',
  params: {
    objectId: complaintId,
    ...(_statusExist !== null && {
      'status_exist[data]': _statusExist,
    }),
    _perPage: 10,
    _page,
    ...(_reverseSort && {
      '_order[loggedAt]': 'DESC',
    }),
  },
}).then((res) => ({
  item: res.data['hydra:member'],
  totalItem: res.data['hydra:totalItems'],
}));

export const getSingleComplaintRequest = (complaintId) => ({
  service: 'customer_complaint_service', method: 'GET', path: `${API_QUERY_COMPLAINT}/${complaintId}`,
});
