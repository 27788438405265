import BackOfficeUtils from 'lib/BackOfficeUtils';
import { compress, isSupportCompress } from 'lib/stringUtils';

const API_QUERY_PRODUCTION = 'api/production_items';
const API_QUERY_AUTOSEND_PRODUCTION = 'api/production_item_auto_sends';
const API_QUERY_SENT_JOB = 'api/production_jobs';
const API_SEND_PRODUCTION = 'api/production_items/send/singleJob';
const API_SEND_PROOFJOB = 'api/production_items/send/proofJob';
const API_SEND_PROMO_NO_PRINTING_JOB = 'api/production_items/send/promo-no-printing';
const API_SEND_PROMO_PRINTING_JOB = 'api/production_items/send/promo-printing';
const API_SEND_PROMO_SAMPLEJOB = 'api/production_items/{id}/sample/produce';
const API_TOGGLE_LOCK = 'api/production_items/{id}/toggle-lock';
const API_REMOVE = 'api/production_items/{id}/remove';
const API_QUERY_WORKFLOW_LOG = 'api/workflow_logs';
const API_UPDATE_PROOF = 'api/production_jobs/{id}/proof/update';
const API_CANCEL_SENT_JOB = 'api/production_jobs/{id}/cancel';
const API_DISABLE_PROOF = 'api/production_items/{id}/disable-proof';
const API_GET_LIST_SKUS = 'api/production_items/gang/{country}/skus';
const API_GET_LIST_PAPERS = 'api/production_items/gang/{country}/papers/{skus}';
const API_GET_LIST_PAPERS_COMPRESS = 'api/production_items/gang/{country}/c-papers';
const API_GET_LIST_REFINEMENTS = 'api/production_items/gang/{country}/refinements/{skus}';
const API_GET_LIST_REFINEMENTS_COMPRESS = 'api/production_items/gang/{country}/c-refinements';
const API_SEND_GANG = 'api/production_items/send/gangJob';

export const getDefaultGangFilter = (country) => ({
  country,
  workflowName: 'production_item',
  status: ['pending', 'sending_gang'],
  productSku: [],
  orderStatus: ['processing'],
  paper: undefined,
  isCustom: 'false',
  canGang: 'true',
});

export const getDefaultDigitalProofFilter = (country) => ({
  country,
  status: ['pending_proof', 'sending_proof', 'acknowledge_proof', 'confirmed_proof', 'removing_proof'],
  orderStatus: ['processing'],
});

export const getDefaultSingleJobFilter = (country) => ({
  country,
  orderIncrementId: [],
  itemId: [],
  productName: [],
  status: ['pending'],
  orderStatus: ['processing'],
  canGang: false,
});

export const getDefaultPromoJobFilter = (country) => ({
  country,
  status: ['pending'],
  orderStatus: ['processing'],
});

export const getDefaultAllJobFilter = (country) => ({
  country,
  orderIncrementId: [],
  itemId: [],
  productName: [],
  status: ['pending', 'pending_proof'],
  orderStatus: ['processing'],
});

export const getProductionItemRequest = async (filter, pagination, order) => {
  const params = {
    page: pagination.page,
    _perPage: pagination.perPage,
    order,
  };

  if (filter.country === 'sg' || filter.country === 'my') {
    params['not[storeCode]'] = 'th';
  } else {
    params.storeCode = filter.country;
  }
  if (filter.productName != null) {
    params.productName = filter.productName;
  }

  if (filter.hasDigitalProof != null) {
    params.hasDigitalProof = filter.hasDigitalProof;
  }

  if (filter.orderIncrementId != null) {
    params.orderIncrementId = filter.orderIncrementId;
  }

  if (filter.workflowName != null) {
    params.workflowName = filter.workflowName;
  }

  if (filter.couponCode != null) {
    params.couponCode = filter.couponCode;
  }

  if (filter.canGang != null) {
    params.canGang = filter.canGang;
    if (Array.isArray(filter.productSku) && filter.productSku.length > 0) {
      params['or[]productSku'] = filter.productSku;
      params['or[]canGang'] = true;
    }
  } else if (Array.isArray(filter.productSku) && filter.productSku.length > 0) {
    params.productSku = filter.productSku;
  }

  if (filter.status != null) {
    params.status = filter.status;
  }

  if (filter.itemId != null) {
    params.itemId = filter.itemId;
  }

  if (filter.paper != null) {
    params.paper = filter.paper;
  }

  if (filter.refinement != null) {
    params.refinement = filter.refinement;
  }

  if (filter.productOptionDelivery != null) {
    params.productOptionDelivery = filter.productOptionDelivery;
  }

  if (filter.productOptionProduction != null) {
    params.productOptionProduction = filter.productOptionProduction;
  }

  if (filter.rushFlag != null) {
    params.rushFlag = filter.rushFlag;
  }

  if (filter.sameDayFlag != null) {
    params.sameDayFlag = filter.sameDayFlag;
  }

  if (filter.newProductFlag != null) {
    params.newProductFlag = filter.newProductFlag;
  }

  if (filter.isCustom != null) {
    params.isCustom = filter.isCustom;
  }

  if (filter.orderStatus != null) {
    params.orderStatus = filter.orderStatus;
  }

  return {
    service: 'prepress', method: 'GET', path: API_QUERY_PRODUCTION, params,
  };
};

export const getAutoSendProductionItemRequest = async (filter, pagination, order) => {
  console.log({ filter, pagination, order });
  const params = {
    page: pagination.page,
    _perPage: pagination.perPage,
    order,
  };

  if (filter.country === 'sg' || filter.country === 'my') {
    params.country = ['sg', 'my'];
  } else {
    params.country = filter.country;
  }

  if (filter['createdAt[before]'] != null) {
    params['createdAt[before]'] = new Date(filter['createdAt[before]'].getTime() + 86400000);
  }
  if (filter['createdAt[after]'] != null) {
    params['createdAt[after]'] = new Date(filter['createdAt[after]'].getTime() + 0);
  }

  return {
    service: 'prepress', method: 'GET', path: API_QUERY_AUTOSEND_PRODUCTION, params,
  };
};

export const getSentJobRequest = (filter, pagination, order) => {
  const params = {
    _page: pagination.page,
    _perPage: pagination.perPage,
    _order: order,
  };

  if (filter.orderIncrementId != null && filter.orderIncrementId !== '') {
    params['productionItems.orderIncrementId'] = filter.orderIncrementId;
  }

  if (filter.itemId != null && filter.itemId !== '') {
    params['productionItems.itemId'] = filter.itemId;
  }

  if (filter.jobNumber != null && filter.jobNumber !== '') {
    params.jobNumber = filter.jobNumber;
  }

  return {
    service: 'prepress', method: 'GET', path: API_QUERY_SENT_JOB, params,
  };
};

export const getSingleProductionItem = async (id) => {
  const result = await BackOfficeUtils.request('prepress', {
    method: 'GET',
    path: `${API_QUERY_PRODUCTION}/${id}`,
  });

  return result;
};

export const getSingleProductionJob = async (id) => {
  const result = await BackOfficeUtils.request('prepress', {
    method: 'GET',
    path: `${API_QUERY_SENT_JOB}/${id}`,
  });

  return result;
};

export const getWorkflowLog = async ({ subjectClass, subjectId, createdAtBefore = null }) => {
  const result = await BackOfficeUtils.request('prepress', {
    method: 'GET',
    path: API_QUERY_WORKFLOW_LOG,
    params: {
      subjectClass: encodeURIComponent(subjectClass),
      subjectId,
      _perPage: 30,
      _page: 1,
      _order: {
        createdAt: 'DESC',
      },
      ...(createdAtBefore != null ? {
        createdAt: {
          strictly_before: encodeURIComponent(createdAtBefore),
        },
      } : {}),
    },
  });

  return result;
};

export const sendSingleJob = async (data) => {
  const result = await BackOfficeUtils.request('prepress', {
    method: 'POST',
    path: API_SEND_PRODUCTION,
    data,
  });

  return result;
};

export const sendDigitalProofJob = async (data) => {
  const result = await BackOfficeUtils.request('prepress', {
    method: 'POST',
    path: API_SEND_PROOFJOB,
    data,
  });

  return result;
};

export const sendPromoSampleItem = async (id, data) => {
  const result = await BackOfficeUtils.request('prepress', {
    method: 'PUT',
    path: API_SEND_PROMO_SAMPLEJOB.replace('{id}', id),
    data,
  });

  return result;
};

export const cancelSentJob = async (id, data) => {
  const result = await BackOfficeUtils.request('prepress', {
    method: 'POST',
    path: API_CANCEL_SENT_JOB.replace('{id}', id),
    data,
  });

  return result;
};

export const toggleLock = async (id) => {
  const result = await BackOfficeUtils.request('prepress', {
    method: 'PUT',
    path: API_TOGGLE_LOCK.replace('{id}', id),
  });

  return result;
};

export const updateProof = async (id, data) => {
  const result = await BackOfficeUtils.request('prepress', {
    method: 'POST',
    path: API_UPDATE_PROOF.replace('{id}', id),
    data,
  });

  return result;
};

export const disableProof = async (id) => {
  const result = await BackOfficeUtils.request('prepress', {
    method: 'PUT',
    path: API_DISABLE_PROOF.replace('{id}', id),
    data: {},
  });

  return result;
};

export const sendPromoNoPrintingJob = async (data) => {
  const result = await BackOfficeUtils.request('prepress', {
    method: 'POST',
    path: API_SEND_PROMO_NO_PRINTING_JOB,
    data,
  });

  return result;
};

export const sendPromoPrintingJob = async (data) => {
  const result = await BackOfficeUtils.request('prepress', {
    method: 'POST',
    path: API_SEND_PROMO_PRINTING_JOB,
    data,
  });

  return result;
};

export const getListSkus = async (countryRaw) => {
  const country = countryRaw === 'my' || countryRaw === 'sg' ? 'my,sg' : countryRaw;
  const result = await BackOfficeUtils.request('prepress', {
    method: 'GET',
    path: API_GET_LIST_SKUS.replace('{country}', country),
  });

  return result;
};

export const getListPapers = async (countryRaw, skusRaw, params) => {
  const country = countryRaw === 'my' || countryRaw === 'sg' ? 'my,sg' : countryRaw;
  const skus = skusRaw.length > 0 ? skusRaw : ['dummy-sku'];
  if (isSupportCompress()) {
    const result = await BackOfficeUtils.request('prepress', {
      method: 'GET',
      path: API_GET_LIST_PAPERS_COMPRESS.replace('{country}', country),
      params: {
        ...params,
        csku: encodeURIComponent(Buffer.from(await compress(skus.join(','))).toString('base64')),
      },
    });

    return result;
  }
  const result = await BackOfficeUtils.request('prepress', {
    method: 'GET',
    path: API_GET_LIST_PAPERS.replace('{country}', country).replace('{skus}', skus.join(',')),
    params,
  });

  return result;
};

export const getListRefinements = async (countryRaw, skusRaw) => {
  const country = countryRaw === 'my' || countryRaw === 'sg' ? 'my,sg' : countryRaw;
  const skus = skusRaw.length > 0 ? skusRaw : ['dummy-sku'];
  if (isSupportCompress()) {
    const result = await BackOfficeUtils.request('prepress', {
      method: 'GET',
      path: API_GET_LIST_REFINEMENTS_COMPRESS.replace('{country}', country),
      params: {
        csku: encodeURIComponent(Buffer.from(await compress(skus.join(','))).toString('base64')),
      },
    });

    return result;
  }
  const result = await BackOfficeUtils.request('prepress', {
    method: 'GET',
    path: API_GET_LIST_REFINEMENTS.replace('{country}', country).replace('{skus}', skus.join(',')),
  });

  return result;
};

const getMarketplacePartnerCache = {};

export const getMarketplacePrintingPartner = async (serviceCountries, useCache = true) => {
  const cacheKey = JSON.stringify(serviceCountries);
  if (useCache) {
    if (getMarketplacePartnerCache[cacheKey] !== undefined) {
      return getMarketplacePartnerCache[cacheKey];
    }
  }
  getMarketplacePartnerCache[cacheKey] = await BackOfficeUtils.request('marketplace', {
    method: 'GET',
    path: 'v1/printingpartner/list',
    params: {
      serviceCountry: serviceCountries,
    },
  });

  return getMarketplacePartnerCache[cacheKey];
};

export const sengGangManual = async (country, customSupplier, items, downloadName = 'export.xml') => {
  const download = await BackOfficeUtils.download('prepress', {
    method: 'POST',
    path: API_SEND_GANG,
    data: {
      items,
      countryCode: country.toUpperCase(),
      customSupplier,
      type: 'manual',
    },
  });
  const href = URL.createObjectURL(download.data);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute(
    'download',
    downloadName,
  );
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const sengGangAuto = (country, customSupplier, items, force, deliveryOrder = false, shippingAddresses = null) => BackOfficeUtils.request('prepress', {
  method: 'POST',
  path: API_SEND_GANG,
  data: {
    items,
    countryCode: country.toUpperCase(),
    customSupplier,
    type: 'auto',
    ...force ? { force } : {},
    deliveryOrder,
    shippingAddresses,
  },
});

export const removeProductionItem = async (id) => {
  const result = await BackOfficeUtils.request('prepress', {
    method: 'PUT',
    path: API_REMOVE.replace('{id}', id),
    data: {},
  });

  return result;
};

export const removeProductionItemCheck = async (id) => {
  const result = await BackOfficeUtils.request('prepress', {
    method: 'GET',
    path: API_REMOVE.replace('{id}', id),
  });

  return result;
};
