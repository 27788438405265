import BackOfficeUtils from 'lib/BackOfficeUtils';

const API_CONFIG = 'api/global-config';
const API_LIST_CONFIG = 'api/global-config/list';

const queue = {};

export const getConfigs = async (path) => {
  const queueKey = Array.isArray(path) ? path.join() : path;
  if (!(queueKey in queue)) {
    queue[queueKey] = BackOfficeUtils.request('configuration_service', {
      method: 'get',
      path: API_CONFIG,
      params: {
        path,
      },
    });
  }

  return queue[queueKey];
};

export const getListConfig = async (exclusiveStartKey, path, limit = 30) => {
  const result = await BackOfficeUtils.request('configuration_service', {
    method: 'get',
    path: API_LIST_CONFIG,
    params: {
      exclusiveStartKey,
      limit,
      path,
    },
  });

  return result;
};

export const saveConfigs = async (values) => {
  const result = await BackOfficeUtils.request('configuration_service', {
    method: 'post',
    path: API_CONFIG,
    data: values.map(({ path, value }) => ({ path, value })),
  });

  return result;
};
