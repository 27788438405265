import { getPPCInstance, pushPriceToPPCInstance, checkPriceIndexProgress } from 'lib/PPCService/request';
import { apiAction } from 'lib/common/middleware/backOfficeApiMiddleware';
import { WEBSOCKET_DISCONNECT, WEBSOCKET_CONNECT } from 'lib/common/middleware/websocketMiddleware';

export const RECEIVE_PPC_INSTANCES = 'RECEIVE_PPC_INSTANCES';
export const OPEN_REINDEX_MODAL = 'OPEN_REINDEX_MODAL';
export const CLOSE_REINDEX_MODAL = 'CLOSE_REINDEX_MODAL';
export const START_INDEXING = 'START_INDEXING';
export const REINDEX_FINISHED = 'REINDEX_FINISHED';

export function closeReindexModal() {
  return (dispatch) => {
    dispatch({ type: CLOSE_REINDEX_MODAL });
    dispatch({ type: WEBSOCKET_DISCONNECT });
  };
}

function receivePPCIndexProgress(data) {
  if ('tracking' in data) {
    return {
      type: WEBSOCKET_CONNECT,
      payload: {
        url: data.tracking.wss,
      },
    };
  }

  return {
    type: REINDEX_FINISHED,
  };
}

export function startReindex(priceInstance) {
  return (dispatch) => {
    dispatch({ type: START_INDEXING, priceInstance });
    dispatch(apiAction({
      request: pushPriceToPPCInstance(priceInstance.code),
      onSuccess: receivePPCIndexProgress,
      label: 'ppc-service',
    }));
  };
}

function checkReindex(priceInstance) {
  return (dispatch) => {
    dispatch({ type: START_INDEXING, priceInstance });
    dispatch(apiAction({
      request: checkPriceIndexProgress(priceInstance.code),
      onSuccess: receivePPCIndexProgress,
      label: 'ppc-service',
    }));
  };
}

function receivePPCInstance(data) {
  return {
    type: RECEIVE_PPC_INSTANCES,
    items: data.items,
    total: data.items.length,
  };
}

export function fetchPPCInstance() {
  return apiAction({
    request: getPPCInstance(),
    onSuccess: receivePPCInstance,
    label: 'ppc-service',
  });
}

export function openReindexModal(priceInstance) {
  return (dispatch) => {
    dispatch({ type: OPEN_REINDEX_MODAL, priceInstance });
    if (priceInstance.state === 'indexing') {
      dispatch(checkReindex(priceInstance));
    }
  };
}
