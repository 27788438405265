import { generatePath } from 'react-router-dom';
import BackOfficeUtils from '../../BackOfficeUtils';

const API_CUSTOMER = 'api/customers';

export const getDashboardDefaultFilter = () => ({
  email: '',
  name: '',
  storeId: '',
  stores: [],
});

export function getCustomersRequest(filter, pagination, order) {
  const params = {
    _page: pagination.page,
    _perPage: pagination.perPage,
    _order: order,
  };

  if (filter.name) {
    params.name = filter.name;
  }

  if (filter.email) {
    params.email = filter.email;
  }

  if (filter.storeId) {
    params.storeCustomerId = filter.storeId;
  }

  if (filter.stores.length > 0) {
    params.store = filter.stores;
  }

  return {
    service: 'customer', method: 'GET', path: API_CUSTOMER, params,
  };
}

export const getCustomer = (customerId) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'GET',
    path: `${API_CUSTOMER}/${customerId}`,
  },
);

export const searchCustomer = (store, search) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'GET',
    path: `${API_CUSTOMER}`,
    params: {
      search,
      store,
    },
  },
);

export const isGroupOrg = (organization) => organization.groupType === 'group';

export const getOrgUrl = (id) => generatePath('/customer/organizations/:id', { id });
