import BackOfficeUtils from 'lib/BackOfficeUtils';
import { numberFormatter } from 'lib/common/formatters';
import moment from 'moment';
import config from '../config';
import { getSelectOptions } from './leads';
import { getMappedFilter } from '../../common/filters';
import { readableShortDateFormatter } from '../../common/formatters';

const API_ORG = 'api/organizations';
const API_PIPEDRIVE_ORG = 'api/pipedrive-organizations';
const API_INDIVIDUAL = 'api/organizations/individual_organizations';
const API_GROUP = 'api/organizations/group_organizations';
const API_ORG_DUPLICATES = 'api/organizations/duplicates';

// Default Filter
export const getDefaultOrganizationsFilter = () => ({
  search: { type: 'referenceNumber', text: '' },
  store: [],
  type: [],
  industry: [],
  size: [],
  accountOwner: [],
  serviceLevel: [],
});

export const getOrganizationsRequest = (filter, pagination, order) => {
  const params = {
    _page: pagination.page,
    _perPage: pagination.perPage,
    _order: order,
    ...getMappedFilter(
      filter,
      {
        store: 'store',
        type: 'type',
        industry: 'industry',
        serviceLevel: 'serviceLevel',
        accountOwner: 'accountOwner.id',
        size: 'companySize',
        status: 'status',
      },
    ),
  };

  if (filter.search) {
    if (filter.search.text && (filter.search.text !== '')) {
      params[filter.search.type] = filter.search.text;
    }
  }

  return {
    service: 'customer', method: 'GET', path: API_ORG, params,
  };
};

export const updateGroupOrganization = (orgId, data) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'PUT',
    path: `${API_GROUP}/${orgId}`,
    data,
  },
);

export const updateOrganization = (orgId, data) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'PUT',
    path: `${API_INDIVIDUAL}/${orgId}`,
    data,
  },
);

export const getOrganizationActions = (orgId) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'GET',
    path: `${API_INDIVIDUAL}/${orgId}/actions`,
  },
).then((data) => data.items);

export const approveOrganization = (orgId) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'POST',
    path: `${API_INDIVIDUAL}/${orgId}/actions`,
    data: { action: 'verify' },
  },
);

export const deleteOrganization = (orgId) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'POST',
    path: `${API_INDIVIDUAL}/${orgId}/actions`,
    data: { action: 'delete' },
  },
);

export const mergeOrganizations = (fromOrgId, toOrgId) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'POST',
    path: `${API_INDIVIDUAL}/${fromOrgId}/merge`,
    data: { to: toOrgId },
  },
);

export const updateServiceLevel = (orgId, serviceLevel) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'PUT',
    path: `api/organizations/${orgId}/update_service_level`,
    data: { serviceLevel },
  },
);

export const updateAccountOwner = (orgId, accountOwner, handoverDate) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'PUT',
    path: `api/organizations/${orgId}/update_account_owner`,
    data: {
      accountOwner,
      handoverDate: moment(handoverDate).format('YYYY-MM-DD'),
    },
  },
);

export const getPipedriveOrganization = ({ store, organizationId }) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'GET',
    path: `${API_PIPEDRIVE_ORG}/${organizationId}?store=${store}`,
  },
);

export const getOrganization = (orgId) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'GET',
    path: `${API_ORG}/${orgId}`,
  },
);

export const getGroupOrganization = (orgId) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'GET',
    path: `${API_GROUP}/${orgId}`,
  },
);

export const getActivityByStore = (store) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'GET',
    path: 'api/activities',
    params: { store },
  },
).then((data) => data.items);

export const bulkAction = (type, params, store, organizations) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'POST',
    path: 'api/organizations/bulk_action',
    data: {
      type,
      params,
      store,
      organizations,
    },
  },
);

export const addOrganizationCustomer = (data) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'POST',
    path: 'api/organization_customers',
    data,
  },
);

export const removeOrganizationCustomer = (pathQuery) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'DELETE',
    path: `api/organization_customers/${pathQuery}`,
  },
);

export const getUnverified = ({ store, pagination, order }) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'GET',
    path: API_ORG,
    params: {
      _page: pagination.page,
      _perPage: pagination.perPage,
      _order: order,
      store,
      status: 'unverified',

    },
  },
);
export const getDuplicates = () => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'GET',
    path: API_ORG_DUPLICATES,
    params: {},
  },
);

export const mergeDuplicates = (
  { duplicateId, source, destination },
) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'PUT',
    path: `api/organizations/duplicates/${duplicateId}/merge`,
    data: {
      from: source,
      to: destination,
    },
  },
);

export const getDeal = ({
  dealId, store,
}) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'GET',
    path: `api/deals/${dealId}?store=${store}`,
  },
);

export const importDeal = ({
  dealId, store, orgId, status,
}) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'POST',
    path: 'api/deals/import',
    data: {
      dealId: parseInt(dealId, 10),
      store,
      orgId,
      status,
    },
  },
);

export const importByOrganizationId = ({
  orgId, status, store,
}) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'POST',
    path: 'api/pipedrive-organizations/import',
    data: {
      orgId,
      status,
      store,
    },
  },
);

// Select Options
export const getTypeSelect = () => getSelectOptions(config.organizationTypes);
export const getSizeSelect = () => getSelectOptions(config.organizationSizes);
export const getIndustrySelect = () => getSelectOptions(config.organizationIndustries);
export const getStatusSelect = () => getSelectOptions(config.organizationStatus);
export const getTierSelect = () => getSelectOptions(config.organizationTiers);

// Formatters
export const industryFormatter = (cell) => {
  const { organizationIndustries } = config;
  const industry = organizationIndustries.find((industryObj) => cell === industryObj.key);

  return industry ? industry.value : cell;
};
export const sizeFormatter = (cell) => {
  const { organizationSizes } = config;
  const size = organizationSizes.find((sizeObj) => cell === sizeObj.key);

  return size ? size.value : cell;
};
export const typeFormatter = (cell) => {
  const { organizationTypes } = config;
  const type = organizationTypes.find((typeObj) => cell === typeObj.key);

  return type ? type.value : cell;
};
export const statusFormatter = (cell) => {
  const { organizationStatus } = config;
  const status = organizationStatus.find((statusObj) => cell === statusObj.key);

  return status ? status.value : cell;
};
export const tierFormatter = (cell) => {
  const { organizationTiers } = config;
  const tier = organizationTiers.find((tierObj) => cell === tierObj.key);

  return tier ? tier.value : cell;
};
export const groupTypeFormatter = (cell) => {
  const { organizationGroupTypes } = config;
  const groupType = organizationGroupTypes.find((groupTypeObj) => cell === groupTypeObj.key);

  return groupType ? groupType.value : cell;
};
export const customerSinceFormatter = (storeData) => (
  (storeData === null) ? undefined : readableShortDateFormatter(storeData.registrationDate)
);
export const lastOrderNumber = (storeData) => (
  (storeData === null) ? undefined : storeData.lastOrderNumber
);
export const firstOrderDateFormatter = (storeData) => (
  (storeData === null) ? undefined : readableShortDateFormatter(storeData.firstOrder)
);
export const lastOrderFormatter = (storeData) => (
  (storeData === null) ? undefined : readableShortDateFormatter(storeData.lastOrder)
);
export const lastOrderValueFormatter = (storeData) => (
  (storeData === null) ? undefined : numberFormatter(storeData.lastOrderValue)
);
export const lifeTimeOrdersValueFormatter = (storeData) => (
  (storeData === null) ? undefined : numberFormatter(storeData.lifeTimeOrdersValue)
);
export const lifeTimeOrdersFormatter = (storeData) => (
  (storeData === null) ? undefined : storeData.lifeTimeOrders
);
export const lastYearOrdersFormatter = (storeData) => (
  (storeData === null) ? undefined : storeData.lastYearOrders
);
export const lastYearOrdersValueFormatter = (storeData) => (
  (storeData === null) ? undefined : numberFormatter(storeData.lastYearOrdersValue)
);
export const customerDataSourceFormatter = (accountOwner) => (
  (accountOwner === null) ? 'Non-Sales' : 'Sales'
);

// foramatter for nested data in deal
export const dealTitleFormatter = (deal) => (deal ? deal.title : '');
export const dealValueFormatter = (deal) => (deal ? deal.value : '');
export const personNameFormatter = (deal) => (
  (deal && deal.person) ? deal.person.name : ''
);
export const personEmailFormatter = (deal) => (
  (deal && deal.person) ? deal.person.primaryEmail : ''
);
export const personPhoneFormatter = (deal) => (
  (deal && deal.person) ? deal.person.primaryPhone : ''
);
export const matchCustomerNameFormatter = (customer) => (
  customer ? customer.name : ''
);
export const matchCustomerPhoneFormatter = (customer) => (
  customer ? customer.phone : ''
);
export const orgNameFormatter = (deal) => (
  (deal && deal.organization) ? deal.organization.name : ''
);
export const orgTaxidFormatter = (deal) => (
  (deal && deal.organization) ? deal.organization.registrationId : ''
);
export const matchedOrgNameFormatter = (matchedOrg) => (
  (matchedOrg) ? matchedOrg.name : ''
);
export const matchedOrgTaxidFormatter = (matchedOrg) => (
  (matchedOrg) ? matchedOrg.registrationId : ''
);
export const matchedOrgIdFormatter = (matchedOrg) => (
  (matchedOrg) ? matchedOrg.id : ''
);
export const matchedOrgReferenceNumberFormatter = (matchedOrg) => (
  (matchedOrg) ? matchedOrg.referenceNumber : ''
);

// config data
export const getTiers = () => config.organizationTiers;
export const getDefaultTier = () => config.organizationTiers.find((el) => el.key === 'general');
export const getTierByKey = (key) => config.organizationTiers.find((el) => el.key === key);
