import { combineReducers } from 'redux';
import gangView from './gangjob';
import digitalProofView from './digitalproofjob';
import singlejobView from './singlejob';
import promoView from './promojob';
import allView from './allitem';
import sentJobView from './sentjob';
import autoJobView from './autosinglejob';

export default combineReducers({
  gangView,
  digitalProofView,
  singlejobView,
  promoView,
  allView,
  sentJobView,
  autoJobView,
});
