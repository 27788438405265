import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import Auth from 'lib/auth/Auth';
import { authUser as authUserAction } from 'lib/auth/actions';
import Loadable from 'react-loadable';
import { func } from 'prop-types';
import LogRocket from 'logrocket';
import { authType } from 'lib/auth/types';
import { fetchLookupUsers as fetchLookupUsersAction } from './lib/User/actions';

const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse" /></div>;

// Containers
const GogoprintLayout = Loadable({
  loader: () => import('./containers/GogoprintLayout/GogoprintLayout'),
  loading,
});

const mapStateToProps = state => ({
  auth: state.auth,
});

function mapDispatchToProps(dispatch) {
  return {
    authUser: user => dispatch(authUserAction(user)),
    fetchLookupUsers: user => dispatch(fetchLookupUsersAction(user)),
  };
}

class Router extends Component {
  async componentDidMount() {
    try {
      const user = await Auth.currentSession();
      LogRocket.identify(user.idToken.payload.email, {
        name: user.idToken.payload.name,
        email: user.idToken.payload.email,
      });

      const { authUser, fetchLookupUsers } = this.props;
      authUser(user);
      fetchLookupUsers();
    } catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
  }

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route
            path="/"
            name="Home"
            render={routeProps => (
              <GogoprintLayout {...routeProps} {...this.props} />
            )}
          />
        </Switch>
      </HashRouter>
    );
  }
}

Router.propTypes = {
  auth: authType.isRequired,
  authUser: func.isRequired,
  fetchLookupUsers: func.isRequired,
};

const ConnectedRouter = connect(mapStateToProps, mapDispatchToProps)(Router);

export default ConnectedRouter;
