import {
  RECEIVE_PROMO_ITEMS_RESPONSE,
  UPDATE_PROMO_ITEMS_REQUEST,
} from 'lib/Production/actions/promojob';
import { API_END, API_START } from 'lib/common/actions/types';
import { getDefaultPromoJobFilter } from 'lib/Production/services/production';

const initialState = {
  promo_item_sample: {
    th: {
      items: [],
      filter: getDefaultPromoJobFilter('th'),
      order: { orderedAt: 'DESC' },
      pagination: {
        page: 1,
        perPage: 30,
      },
      total: 0,
      loading: false,
    },
    my: {
      items: [],
      filter: getDefaultPromoJobFilter('my'),
      order: { orderedAt: 'DESC' },
      pagination: {
        page: 1,
        perPage: 30,
      },
      total: 0,
      loading: false,
    },
  },
  promo_item_no_printing: {
    th: {
      items: [],
      filter: getDefaultPromoJobFilter('th'),
      order: { orderedAt: 'DESC' },
      pagination: {
        page: 1,
        perPage: 30,
      },
      total: 0,
      loading: false,
    },
    my: {
      items: [],
      filter: getDefaultPromoJobFilter('my'),
      order: { orderedAt: 'DESC' },
      pagination: {
        page: 1,
        perPage: 30,
      },
      total: 0,
      loading: false,
    },
  },
  promo_item_printing: {
    th: {
      items: [],
      filter: getDefaultPromoJobFilter('th'),
      order: { orderedAt: 'DESC' },
      pagination: {
        page: 1,
        perPage: 30,
      },
      total: 0,
      loading: false,
    },
    my: {
      items: [],
      filter: getDefaultPromoJobFilter('my'),
      order: { orderedAt: 'DESC' },
      pagination: {
        page: 1,
        perPage: 30,
      },
      total: 0,
      loading: false,
    },
  },
};

function digitalproofjob(state = initialState, action = null) {
  switch (action.type) {
    case API_START:
    case API_END:
      if (action.payload.startsWith('promo')) {
        const [, promoType, country] = action.payload.split('.');
        return {
          ...state,
          [promoType]: {
            ...state[promoType],
            [country]: { ...state[promoType][country], loading: action.type === API_START },
          },
        };
      }
      break;
    case UPDATE_PROMO_ITEMS_REQUEST:
      return {
        ...state,
        [action.promoType]: {
          ...state[action.promoType],
          [action.country]: {
            ...state[action.promoType][action.country],
            filter: action.filter,
            pagination: action.pagination,
            order: action.order,
          },
        },
      };
    case RECEIVE_PROMO_ITEMS_RESPONSE:
      return {
        ...state,
        [action.promoType]: {
          ...state[action.promoType],
          [action.country]: {
            ...state[action.promoType][action.country],
            items: action.items,
            total: action.total,
          },
        },
      };
    default:
      return state;
  }

  return state;
}

export default digitalproofjob;
