const API_QUERY = 'query';
const API_CREATE = 'restore';

export const getRestoreTask = (limit, start) => ({
  service: 's3restore_service',
  method: 'GET',
  path: API_QUERY,
  params: {
    limit,
    ...(start == null || start === '' ? {} : { start }),
  },
});

export const createRestoreTask = (s3URIs, restoreDays) => ({
  service: 's3restore_service',
  method: 'POST',
  path: API_CREATE,
  data: {
    s3URIs,
    restoreDays,
  },
});
