import {
  createStore, applyMiddleware, compose, combineReducers,
} from 'redux';
import LogRocket from 'logrocket';
import thunkMiddleware from 'redux-thunk';
import reducerAuth from 'lib/auth/reducers';
import reducerUser from 'lib/User/reducers';
import reducerPrepress from 'lib/Prepress/reducers';
import reducerCustomer from 'lib/Customer/reducers';
import reducerPreflight from 'lib/OnlinePreflight/reducers';
import reducerBackOfficeApi from 'lib/common/reducers/backOfficeApi';
import reducerPPCService from 'lib/PPCService/reducers';
import reducerM2SSHService from 'lib/M2SSHService/reducers';
import reducerS3Restore from 'lib/S3Restore/reducers';
import reducerItemState from 'lib/ItemState/reducers';
import reducerCustomerComplaint from 'lib/CustomerComplaint/reducers';
import reducerProduction from 'lib/Production/reducers';
import reducerShipping from 'lib/Shipping/reducers';
import backOfficeApiMiddleware from 'lib/common/middleware/backOfficeApiMiddleware';
import websocketMiddleware from 'lib/common/middleware/websocketMiddleware';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducers = combineReducers({
  auth: reducerAuth,
  backOfficeApi: reducerBackOfficeApi,
  customer: reducerCustomer,
  preflight: reducerPreflight,
  prepress: reducerPrepress,
  user: reducerUser,
  ppcService: reducerPPCService,
  m2SSHService: reducerM2SSHService,
  s3Restore: reducerS3Restore,
  itemState: reducerItemState,
  customerComplaint: reducerCustomerComplaint,
  production: reducerProduction,
  shipping: reducerShipping,
});

let middleware;
if (process.env.REACT_APP_DEV === 'true') {
  middleware = applyMiddleware(thunkMiddleware, backOfficeApiMiddleware);
} else {
  middleware = applyMiddleware(
    thunkMiddleware,
    backOfficeApiMiddleware,
    websocketMiddleware,
    LogRocket.reduxMiddleware(),
  );
}

const store = createStore(reducers, composeEnhancers(middleware));

export default store;
