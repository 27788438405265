import BackOfficeUtils from '../../BackOfficeUtils';
import { getUsersByIds } from '../../User/services/users';

export const getDashboardDefaultFilter = () => ({
  store: [],
});

export const getStaffsRequest = (filter, pagination, order) => {
  const params = {
    _page: pagination.page,
    _perPage: pagination.perPage,
    _order: order,
  };

  if (filter.store && filter.store.length > 0) {
    params.store = filter.store;
  }

  return {
    service: 'customer',
    method: 'GET',
    path: 'api/sales_staffs',
    params,
  };
};

export const addStaff = async (data) => BackOfficeUtils.processRequest('customer', {
  method: 'post',
  path: 'api/sales_staffs',
  data,
});

export const editStaff = async (id, data) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'put',
    path: `api/sales_staffs/${id}`,
    data,
  },
);

export const getSalesStaffByStore = (store = '') => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'GET',
    path: 'api/sales_staffs',
    params: { ...(store !== '' && { store }) },
  },
).then((data) => {
  const { items } = data;

  const userIds = items.map((item) => item.userId);

  return getUsersByIds(userIds).then((users) => items.map((item) => {
    const salesUser = users.find((user) => user.id === item.userId);

    return {
      ...item,
      name: `${salesUser.firstName} ${salesUser.lastName}`,
    };
  }));
});

export const salesStaffFormatter = (staff) => (staff ? `(${staff.salesCode}) ${staff.name}` : null);
