import { apiAction } from 'lib/common/middleware/backOfficeApiMiddleware';
import { getSentJobRequest } from 'lib/Production/services/production';

export const RECEIVE_ALL_SENT_JOB_RESPONSE = 'RECEIVE_ALL_SENT_JOB_RESPONSE';
export const UPDATE_ALL_SENT_JOB_REQUEST = 'UPDATE_ALL_SENT_JOB_REQUEST';

const receiveAllItems = (data) => ({
  type: RECEIVE_ALL_SENT_JOB_RESPONSE,
  items: data['hydra:member'],
  total: data['hydra:totalItems'],
});

export function fetchItems(request) {
  const {
    filter, pagination, order,
  } = request;
  return apiAction(
    {
      request: getSentJobRequest({
        ...filter,
      }, pagination, order),
      onSuccess: receiveAllItems,
      label: 'sentjob',
    },
  );
}

export function updateRequest(request) {
  return {
    ...request,
    type: UPDATE_ALL_SENT_JOB_REQUEST,
  };
}
