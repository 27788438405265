import { getConfigs } from 'lib/ConfigurationService/services/config-service';

const getHolidays = async (country, years) => {
  const mapping = years.reduce((prev, year) => ({
    ...prev,
    [`Public/${country}/Holidays/Company/${year}`]: (data) => ({ companyPublicHolidays: data?.split(',') }),
    [`Public/${country}/Holidays/Factory/${year}`]: (data) => ({ factoryPublicHolidays: data?.split(',') }),
    [`Public/${country}/Holidays/Shipping/${year}`]: (data) => ({ shippingPublicHolidays: data?.split(',') }),
  }), {});

  const configs = await getConfigs(Object.keys(mapping));

  const mergeHoliday = (a, b) => Object.keys(b).reduce((prev, key) => {
    if (key in a) {
      return {
        ...prev,
        ...{ [key]: [...prev[key], ...(b[key] ?? [])] },
      };
    }
    return {
      ...prev,
      ...{ [key]: b[key] ?? [] },
    };
  }, a);

  // eslint-disable-next-line max-len
  return configs.data.configItems.reduce((res, v) => mergeHoliday(res, mapping[v.path](v.value)), {});
};

const getWeekends = async (country) => {
  const mapping = {
    [`Public/${country}/CompanyWeekends`]: (data) => ({ companyWeekends: data.split(',').map((i) => parseInt(i, 10)) }),
    [`Public/${country}/FactoryWeekends`]: (data) => ({ factoryWeekends: data.split(',').map((i) => parseInt(i, 10)) }),
    [`Public/${country}/ShippingWeekends`]: (data) => ({ shippingWeekends: data.split(',').map((i) => parseInt(i, 10)) }),
  };
  const configs = await getConfigs(Object.keys(mapping));

  return configs.data.configItems.reduce((res, v) => ({
    ...res,
    ...mapping[v.path](v.value),
  }), {});
};

export default {
  cognito: {
    AUTH_HOST: 'auth.gogoprint.com',
    REGION: 'ap-southeast-1',
    USER_POOL_ID: 'ap-southeast-1_310HonZYi',
    APP_CLIENT_ID: '7eru44pbtr0i4r1mnv8vb7mrk5',
  },
  site: {
    URL: process.env.REACT_APP_SITE_URL,
  },
  folder_path: {
    item_repo_th: 'prepress://10.110.20.41/item-repo',
    item_repo_sg: 'prepress://10.110.20.41/item-repo',
    temp_proof: 'prepress://ggpth-ss-05/prepress-services/tmp/onlineproofs',
    wip_sg: 'prepress://10.110.20.41/WIP',
    wip_th: 'prepress://10.110.20.41/WIP',
    tmp_item_repo_th: 'P:/item-repo',
    smb_item_repo_th: '//10.110.20.41/item-repo',
    smb_item_repo_sg: '//10.110.20.41/item-repo',
  },
  countries: {
    th: {
      id: 'th',
      label: 'Thailand',
      tz: 'Asia/Bangkok',
      defaultPickupTime: '09:00',
      defaultCoatingBy: '',
      getHolidays: (years) => getHolidays('TH', years),
      getWeekends: () => getWeekends('TH'),
    },
    au: {
      id: 'au',
      label: 'Australia',
      tz: 'Australia/Sydney',
    },
    my: {
      id: 'my',
      label: 'Malaysia',
      tz: 'Asia/Kuala_Lumpur',
      defaultPickupTime: '12:00',
      defaultCoatingBy: '',
      getHolidays: (year) => getHolidays('MY', year),
      getWeekends: () => getWeekends('MY'),
    },
    sg: {
      id: 'sg',
      label: 'Singapore',
      tz: 'Asia/Singapore',
      getHolidays: (year) => getHolidays('SG', year),
      getWeekends: () => getWeekends('SG'),
    },
    id: {
      id: 'id',
      label: 'Indonesia',
      tz: 'Asia/Jakarta',
      defaultPickupTime: '17:00',
      defaultCoatingBy: 'Partner',
    },
    en: {
      id: 'en',
      label: 'English',
      tz: 'Europe/London',
    },
  },
  storeCountries: [
    {
      id: 'au',
      label: 'Australia',
      tz: 'Australia/Sydney',
    },
    {
      id: 'id',
      label: 'Indonesia',
      tz: 'Asia/Jakarta',
      defaultPickupTime: '17:00',
      defaultCoatingBy: 'Partner',
    },
    {
      id: 'my',
      label: 'Malaysia',
      tz: 'Asia/Kuala_Lumpur',
      defaultPickupTime: '12:00',
      defaultCoatingBy: '',
    },
    {
      id: 'sg',
      label: 'Singapore',
      tz: 'Asia/Singapore',
    },
    {
      id: 'th',
      label: 'Thailand',
      tz: 'Asia/Bangkok',
      defaultPickupTime: '09:00',
      defaultCoatingBy: '',
    },
  ],
  flags: {
    isNew: 'New',
    isRush: 'Rush',
    isSameday: 'Same day',
  },
  prepress: {
    ITEM_PER_PAGE: 50,
    FETCH_REPEAT: 10,
    MAX_DROP_FILES: 10,
  },
  preComments: [
    'New artwork upload',
    'Check-in new version',
    'Upload proof file',
  ],
  onlineProofStatus: {
    to_send: 'New',
    waiting_feedback: 'Sent',
    approved: 'Approved',
    rejected: 'Rejected',
    cancelled: 'Cancelled',
  },
  uploadTypes: [
    { value: 'artwork', label: 'Check-in' },
    // { value: 'digital_proof', label: 'Digital Proof' },
    { value: 'online_proof', label: 'Online Proof' },
  ],
  onlineProofSetting: {
    senderEmail: {
      TH: 'onlineproof@gogoprint.co.th',
      MY: 'onlineproof@gogoprint.com.my',
      SG: 'onlineproof@gogoprint.sg',
    },
    ccEmail: {
      TH: [{ label: 'salesco_th@gogoprint.co.th', value: 'salesco_th@gogoprint.co.th' }],
      MY: [],
      SG: [{ label: 'sgteam@gogoprint.sg', value: 'sgteam@gogoprint.sg' }],
    },
    templates: [
      { value: 'online_proof_sent_th_en', label: 'Online Proof Eng [TH Website]' },
      { value: 'online_proof_sent_th_th', label: 'Online Proof TH' },
      { value: 'online_proof_sent_sg_en', label: 'Online Proof Eng [SG Website]' },
    ],
  },
  gangSteps: [
    'generating_thumbnail',
    'create_knack_job',
    'create_marketplace_job',
    'generate_pallet_label',
    'generate_jobsheet',
    'send_mail',
    'check_technical',
    'add_barcode',
    'ftp',
  ],
  issues: {
    TH: ['No_issue', '1item_multiAW', '1page_order_2pages_aw', '2pgsorder_1pgaw', 'aw_cannot_open', 'aw_locked', 'aw_not_on_artboard', 'aw_not_on_template', 'aw_with_border', 'color_changed', 'folding_sample', 'folding_type', 'foldingcheck', 'heavy_file', 'low_resolution_300ppi', 'low_resolution_150ppi', 'no_aw_in_uploadedfile', 'no_bleed','not_spotcolour', 'not_CMYK', 'not_onlyK', 'page_connected', 'page_separated', 'no_diecut', 'text_near_theEdge', 'thin_line_forSpotUV', 'thin_line_in_aw', 'unclear_artwork', 'unclear_spotvarnish', 'white_aw', 'wrong_orientation', 'wrong_page_count', 'wrong_size', 'wrong_size_close', 'wrong_total_color', 'unclear_hotstamp', 'thin_line_for_hotfoil', 'excel_name', 'aw_cant_be_checked_or_saved', 'aw_on_wrong_template', 'aw_with_frame', 'diecut_jpg', 'diecut_unclear', 'not_embed_font', 'not_embed_image', 'aw_has_line', 'safetymargin_3mm', 'safetymargin_10mm', 'safetymargin_15mm', 'safetymargin_calendar', 'safetymargin_5mm', 'not_vector'],
    MY: ['No_issue', '1item_multiAW', '1page_order_2pages_aw', '2pgsorder_1pgaw', 'aw_cannot_open', 'aw_locked', 'aw_not_on_artboard', 'aw_not_on_template', 'aw_with_border', 'color_changed', 'folding_type', 'foldingcheck', 'heavy_file', 'low_resolution_300ppi', 'low_resolution_150ppi', 'no_aw_in_uploadedfile', 'no_bleed', 'not_CMYK', 'not_onlyK', 'not_spotcolour', 'page_connected', 'page_separated', 'excel_runnumber', 'sticker_no_bleed', 'no_diecut', 'sticker_unclear_diecut', 'text_near_theEdge', 'thin_line_forSpotUV', 'thin_line_in_aw', 'unclear_artwork', 'unclear_hotstamp', 'unclear_location_number_line', 'unclear_spotvarnish', 'white_aw', 'wrong_orientation', 'wrong_page_count', 'wrong_size', 'wrong_size_close', 'wrong_total_color', 'thin_line_for_hotfoil', 'excel_name', 'aw_cant_be_checked_or_saved', 'aw_on_wrong_template', 'aw_with_frame', 'diecut_jpg', 'diecut_unclear', 'not_embed_font', 'not_embed_image', 'aw_has_line', 'safetymargin_3mm', 'safetymargin_10mm', 'safetymargin_15mm', 'safetymargin_calendar', 'safetymargin_5mm', 'not_vector'],
    SG: ['No_issue', '1item_multiAW', '1page_order_2pages_aw', '2pgsorder_1pgaw', 'aw_cannot_open', 'aw_locked', 'aw_not_on_artboard', 'aw_not_on_template', 'aw_with_border', 'color_changed', 'folding_type', 'foldingcheck', 'heavy_file', 'low_resolution_300ppi', 'low_resolution_150ppi', 'no_aw_in_uploadedfile', 'no_bleed', 'not_CMYK', 'not_onlyK', 'not_spotcolour', 'page_connected', 'page_separated', 'excel_runnumber', 'sticker_no_bleed', 'no_diecut', 'sticker_unclear_diecut', 'text_near_theEdge', 'thin_line_forSpotUV', 'thin_line_in_aw', 'unclear_artwork', 'unclear_hotstamp', 'unclear_location_number_line', 'unclear_spotvarnish', 'white_aw', 'wrong_orientation', 'wrong_page_count', 'wrong_size', 'wrong_size_close', 'wrong_total_color', 'thin_line_for_hotfoil', 'excel_name', 'aw_cant_be_checked_or_saved', 'aw_on_wrong_template', 'aw_with_frame', 'diecut_jpg', 'diecut_unclear', 'not_embed_font', 'not_embed_image', 'aw_has_line', 'safetymargin_3mm', 'safetymargin_10mm', 'safetymargin_15mm', 'safetymargin_calendar', 'safetymargin_5mm', 'not_vector'],
  },
  storeStatuses: [
    'complete',
    'processing',
    'pending',
    'credit_approved',
    'credit_forapproval',
    'holded_coupon_issued',
    'credit',
    'canceled',
    'holded',
    'closed',
  ],
  ppcCodes: [
    {
      code: 'th-live',
      storeCodes: ['th'],
    },
    {
      code: 'my-live',
      storeCodes: ['my'],
    },
    {
      code: 'my-live',
      storeCodes: ['sg'],
    },
  ],
  orderStatuses: ['processing', 'completed', 'holded', 'closed', 'cancelled'],
  productionItem: {
    normalStatus: [
      'pending',
      'sending',
      'sending_gang',
      'acknowledge',
      'confirmed',
      'switching_to_proof',
    ],
    promoSampleStatus: [
      'pending',
      'produced',
    ],
    digitalProofStatus: [
      'pending_proof',
      'sending_proof',
      'acknowledge_proof',
      'confirmed_proof',
      'removing_proof',
    ],
  },
  commentDepartments: [
    { value: 'generic', label: 'All', filteredDepartment: 'generic,artwork,production,shipment' },
    { value: 'artwork', label: 'Artwork / Design service Only' },
    { value: 'production', label: 'Production Only' },
    { value: 'shipment', label: 'Shipment Only' },
  ],
  shippingProviders: [
    {
      countries: ['TH'],
      providers: [
        {
          code: 'Kerry',
          trackingUrl: 'https://th.kerryexpress.com/en/track/?track=%TrackingCode%',
          extraInputs: [
            {
              code: 'ServiceType',
              type: 'options',
              options: [
                'ND',
                '2D',
              ],
              level: 'shipment',
              getDefaultValue: ({
                currentTime,
                earliestDeliveryDeadline,
              }) => {
                const now = new Date(currentTime.toDateString());
                const deadline = new Date(earliestDeliveryDeadline.toDateString());
                const diff = deadline.getTime() - now.getTime();
                if (diff <= 8.64e+7) {
                  return 'ND';
                }
                return '2D';
              },
            },
          ],
          order: {
            TH: 2,
          },
        },
        {
          code: 'GGP',
          trackingUrl: 'https://www.gogoprint.co.th',
          extraInputs: [
            {
              code: 'ServiceType',
              type: 'options',
              options: [
                'SD',
                '2D',
              ],
              level: 'shipment',
            },
          ],
          order: {
            TH: 0,
          },
        }, {
          code: 'Grab',
          trackingUrl: 'https://express.grab.com/en',
          extraInputs: [
            {
              code: 'ServiceType',
              type: 'options',
              options: [
                'SD',
                '2D',
              ],
              level: 'shipment',
            },
          ],
          order: {
            TH: 0,
          },
        }, {
          code: 'Deliveree',
          trackingUrl: 'https://express.grab.com/en',
          extraInputs: [
            {
              code: 'ServiceType',
              type: 'options',
              options: [
                'SD',
                '2D',
              ],
              level: 'shipment',
            },
          ],
          order: {
            TH: 0,
          },
        }, {
          code: 'Other',
          trackingUrl: 'https://express.grab.com/en',
          extraInputs: [
            {
              code: 'ServiceType',
              type: 'options',
              options: [
                'SD',
                '2D',
              ],
              level: 'shipment',
            },
          ],
          order: {
            TH: 0,
          },
        },

      ],
    },
    {
      countries: ['SG', 'MY'],
      providers: [
        {
          code: 'Shippit',
          trackingUrl: 'https://www.shippit.com/track-your-package/',
          order: {
            SG: 110,
            MY: 110,
          },
        },
        {
          code: 'Teleport Next Day',
          trackingUrl: 'https://teleport.delivery/track',
          order: {
            SG: 101,
            MY: 101,
          },
        },
        {
          code: 'Ninjavan',
          trackingUrl: 'https://www.ninjavan.co/en-my/tracking?id=%TrackingCode%',
          order: {
            SG: 0,
            MY: 0,
          },
        },
        {
          code: 'Poslaju',
          trackingUrl: 'https://tracking.pos.com.my/tracking/%TrackingCode%',
          order: {
            SG: 0,
            MY: 100,
          },
        },
        {
          code: 'QExpress',
          trackingUrl: 'https://www.qxpress.net/Customer/PopupTraceParcels?TrackingNo=%TrackingCode%',
          order: {
            SG: 100,
            MY: 0,
          },
        },
        {
          code: 'XendNow',
          trackingUrl: 'https://www.xendnow.my/trace.aspx?key=%TrackingCode%',
          order: {
            SG: 0,
            MY: 0,
          },
        },
        {
          code: 'Lalamove',
          trackingUrl: 'https://www.lalamove.com/',
          order: {
            SG: 0,
            MY: 0,
          },
        },
        {
          code: 'Skynet',
          trackingUrl: 'https://www.skynet.com.my/track?track=%TrackingCode%',
          order: {
            SG: 0,
            MY: 0,
          },
        },
        {
          code: 'DHL',
          trackingUrl: 'https://www.dhl.com/my-en/home/tracking.html?tracking-id=%TrackingCode%',
          order: {
            SG: 0,
            MY: 0,
          },
        },
        {
          code: 'ABX',
          trackingUrl: 'https://www.tracking.my/abx/%TrackingCode%',
          order: {
            SG: 0,
            MY: 0,
          },
        },
        {
          code: 'TruckDelivery',
          trackingUrl: 'https://www.gogoprint.com/',
          order: {
            SG: 0,
            MY: 0,
          },
        },
        {
          code: 'SelfCollect',
          trackingUrl: 'https://www.gogoprint.com/',
          order: {
            SG: 0,
            MY: 0,
          },
        },
        {
          code: 'Sameday',
          label: 'Sameday SG/MY',
          trackingUrl: 'https://www.gogoprint.com/',
          order: {
            SG: 0,
            MY: 0,
          },
        },
      ],
    },
  ],
  materialColors: [
    { c: 'black', h: '#000', f: '#FFF' },
    { c: 'silver', h: '#9DB2BF', f: '#000' },
    { c: 'gray', h: '#545B77', f: '#000' },
    { c: 'gold', h: '#FFB84C', f: '#FFF' },
    { c: 'blue', h: '#2F58CD', f: '#000' },
    { c: 'wooden', h: '#562B08', f: '#FFF' },
  ],
  configColors: [
    { h: '#610094', f: '#FFF', matches: ['rush'] },
    { h: '#FD841F', f: '#FFF', matches: ['sd', 'sameday'] },
    { h: '#61876E', f: '#FFF', matches: ['new', 'new product'] },
    { h: '#6C9BCF', f: '#FFF', contains: ['production'] },
  ],
};
