import BackOfficeUtils from 'lib/BackOfficeUtils';

const API_QUERY_PRODUCTION = 'api/shipping-jobs';
const API_QUERY_SHIPMENT = 'api/shipment';
const API_QUERY_SHIPPING_ORDERS = 'api/shipping-orders';
const API_QUERY_PRODUCTION_JOBS = 'api/production-jobs';
const API_CREATE_SHIPMENT = 'api/shipment/create';
const API_CANCEL_SHIPMENT = 'api/shipment/cancel-ship';
const API_INBOUND = 'api/shipping-jobs/inbound';
const API_CANCEL_INBOUND = 'api/shipping-jobs/cancel-inbound';
const API_DOWNLOAD_SHIPIT_LABEL = 'api/shipment-package/shipitLabel';

export const defaultInboundFilter = (country) => ({
  orderStatus: 'processing',
  isInbounded: [false],
  jobStatus: [],
  country,
});

export const defaultShippingOrdersFilter = (country) => ({
  orderStatus: 'processing',
  jobStatus: country === 'th' ? ['include_no_inbounded'] : [],
  country,
  hasJobToShip: [true],
});

export const defaultShippingJobsFilter = (country) => ({
  country,
  isShippedAll: [false],
  isConfirmed: [true],
  isCanceled: [false],
  ...(country === 'th' ? {
  } : {
    isInboundedAll: [true],
  }),
});

export const defaultShipmentFilter = (country) => ({
  'shippingAddress.country': country,
});

export const defaultInboundTodayFilter = (country) => ({
  isInbounded: [true],
  'inboundedAt[after]': new Date(new Date().setHours(0, 0, 0, 0)),
  'inboundedAt[before]': new Date(new Date().setHours(0, 0, 0, 0)),
  jobStatus: ['include_no_confirmed', 'include_canceled', 'include_shipped'],
  country,
});

const filterToParams = (filter) => {
  const params = {};
  if (filter.country === 'sg' || filter.country === 'my') {
    params['item.country'] = ['sg', 'my'];
  } else {
    params['item.country'] = filter.country;
  }
  params.workflowStatus = ['pending']; //  show only pending item by default
  if (filter.orderStatus != null) {
    params['item.orderStatus'] = filter.orderStatus;
  }
  if (filter.isInbounded != null) {
    params.workflowStatus = [...params.workflowStatus, 'inbounded'];
    params['exists[inboundedAt]'] = !Array.isArray(filter.isInbounded) ? filter.isInbounded : filter.isInbounded[0];
    if (params['exists[inboundedAt]']) {
      if (filter['inboundedAt[before]'] != null) {
        params['inboundedAt[before]'] = new Date(filter['inboundedAt[before]'].getTime() + 86400000);
      }
      if (filter['inboundedAt[after]'] != null) {
        params['inboundedAt[after]'] = new Date(filter['inboundedAt[after]'].getTime() + 0);
      }
    }
  }
  params.isConfirmed = true;
  params.isCanceled = false;
  params.isShippedByOthers = false;
  if (Array.isArray(filter.jobStatus)) {
    if (filter.jobStatus.includes('include_no_confirmed')) {
      params.isConfirmed = undefined;
    }
    if (filter.jobStatus.includes('include_canceled')) {
      params.isCanceled = undefined;
    }
    if (filter.jobStatus.includes('include_shipped')) {
      params.isShippedByOthers = undefined;
      params.workflowStatus = [...params.workflowStatus, 'shipped'];
    }
  }
  return params;
};

export const getShippingJobRequest = async (filter, pagination, order) => {
  const params = {
    _page: pagination.page,
    _perPage: pagination.perPage,
    _order: order,
    ...filterToParams(filter),
  };
  return {
    service: 'shipping', method: 'GET', path: API_QUERY_PRODUCTION, params,
  };
};

const filterToParamsOrder = (filter) => {
  const { jobStatus, hasJobToShip, ...restFilter } = filter;
  const jobStatusFix = Array.isArray(jobStatus) ? jobStatus : [];
  const hasJobToShipFix = Array.isArray(hasJobToShip) ? hasJobToShip[0] : hasJobToShip;
  return {
    ...restFilter,
    ...(restFilter.country === 'sg' || restFilter.country === 'my') ? { country: ['sg', 'my'] } : {},
    ...jobStatusFix.includes('include_no_inbounded')
      ? { 'items.jobs.workflowStatus': ['pending', 'inbounded'] }
      : { 'items.jobs.workflowStatus': ['inbounded'] },
    ...jobStatusFix.includes('include_canceled')
      ? {}
      : { 'items.jobs.isCanceled': false },
    hasJobToShip: hasJobToShipFix,
  };
};

const filterToParamsJob = (filter) => {
  const {
    isConfirmed, isInboundedSome, isCanceled, isInboundedAll, isShippedAll, ...restFilter
  } = filter;
  return {
    ...restFilter,
    ...(restFilter.country === 'sg' || restFilter.country === 'my') ? { country: ['sg', 'my'] } : {},
    isConfirmed: Array.isArray(isConfirmed) ? isConfirmed[0] : isConfirmed,
    isInboundedSome: Array.isArray(isInboundedSome) ? isInboundedSome[0] : isInboundedSome,
    isInboundedAll: Array.isArray(isInboundedAll) ? isInboundedAll[0] : isInboundedAll,
    isShippedAll: Array.isArray(isShippedAll) ? isShippedAll[0] : isShippedAll,
    isCanceled: Array.isArray(isCanceled) ? isCanceled[0] : isCanceled,
  };
};

export const getShippingOrderRequest = async (filter, pagination, order) => {
  const params = {
    _page: pagination.page,
    _perPage: pagination.perPage,
    _order: order,
    ...filterToParamsOrder(filter),
  };
  return {
    service: 'shipping', method: 'GET', path: API_QUERY_SHIPPING_ORDERS, params,
  };
};

export const getShippingJobDashboardRequest = async (filter, pagination, order) => {
  const params = {
    _page: pagination.page,
    _perPage: pagination.perPage,
    _order: order,
    ...filterToParamsJob(filter),
  };
  return {
    service: 'shipping', method: 'GET', path: API_QUERY_PRODUCTION_JOBS, params,
  };
};

export const getShipmentRequest = async (filter, pagination, order) => {
  const params = {
    _page: pagination.page,
    _perPage: pagination.perPage,
    _order: order,
    ...{
      ...filter,
      ...(filter['shippingAddress.country'] === 'sg' || filter['shippingAddress.country'] === 'my')
        ? { 'shippingAddress.country': ['sg', 'my'] }
        : {},
    },
  };
  return {
    service: 'shipping', method: 'GET', path: API_QUERY_SHIPMENT, params,
  };
};

export const getShippingJob = async (country, filter) => {
  const params = {};
  if (country === 'sg' || country === 'my') {
    params['item.country'] = ['sg', 'my'];
  } else {
    params['item.country'] = country;
  }
  const result = await BackOfficeUtils.request('shipping', {
    method: 'GET',
    path: API_QUERY_PRODUCTION,
    params: {
      ...params,
      ...filter,
    },
  });

  return result;
};

export const getShippingOrder = async (country, filter) => {
  const result = await BackOfficeUtils.request('shipping', {
    method: 'GET',
    path: API_QUERY_SHIPPING_ORDERS,
    params: {
      ...(country === 'sg' || country === 'my') ? { country: ['sg', 'my'] } : {},
      ...filter,
    },
  });

  return result;
};

export const createShipment = async (data) => {
  const result = await BackOfficeUtils.request('shipping', {
    method: 'POST',
    path: API_CREATE_SHIPMENT,
    data,
  });

  return result;
};

export const inbound = async (shippingJobIds) => {
  const result = await BackOfficeUtils.request('shipping', {
    method: 'POST',
    path: API_INBOUND,
    data: {
      shippingJobIds,
    },
  });

  return result;
};

export const cancelInbound = async (shippingJobIds) => {
  const result = await BackOfficeUtils.request('shipping', {
    method: 'POST',
    path: API_CANCEL_INBOUND,
    data: {
      shippingJobIds,
    },
  });

  return result;
};

export const cancelShipment = async (shipmentId) => {
  const result = await BackOfficeUtils.request('shipping', {
    method: 'POST',
    path: API_CANCEL_SHIPMENT,
    data: {
      shipmentId,
    },
  });

  return result;
};

export const getConfirmTextForInboundJob = (job) => {
  if (job.isCanceled) {
    return `Job "${job.jobNumber}" is "canceled" for item "${job.item.itemId}"`;
  }
  if (!job.isConfirmed) {
    return `Job "${job.jobNumber}" is "not confirmed" for item "${job.item.itemId}"`;
  }
  if (job.item.orderStatus !== 'processing') {
    return `Order status is not "processing" for item "${job.item.itemId}"`;
  }
  return null;
};

export const downloadShipitLabel = async (shipmentPackageId) => {
  const result = await BackOfficeUtils.request('shipping', {
    method: 'POST',
    path: API_DOWNLOAD_SHIPIT_LABEL,
    data: { shipmentPackageId },
  });

  return result;
};
