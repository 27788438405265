import React, { useState } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { Authenticator, View, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './App.scss';
import { Provider } from 'react-redux';
import store from 'store';
import ConnectedRouter from 'ConnectedRouter';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import pointer from 'assets/img/icons/pointer.png';
import { amplifyExport, authExport } from './lib/auth/aws-export';

Amplify.configure(amplifyExport);
Auth.configure(authExport);
library.add(fas, far);

function App() {
  return (
    <Authenticator.Provider>
      <RouteApp />
    </Authenticator.Provider>
  );
}

function RouteApp() {
  const { route } = useAuthenticator((context) => [context.route]);
  const [clicked, setClicked] = useState(false);
  return route === 'authenticated' ? (
    <View>
      <Provider store={store}>
        <ConnectedRouter />
      </Provider>
    </View>
  ) : (
    <div id="aws-login-container">
      {Array.from(Array(11 * 11).keys()).map(() => (<div className="aws-login-cell" />))}
      <button
        type="button"
        id="aws-login-button"
        className={clicked ? 'clicked' : ''}
        onClick={() => {
          setClicked(true);
          Auth.federatedSignIn();
        }}
      >
        Sign in Backoffice
        <img alt="Click here" src={pointer} />
      </button>
    </div>
  );
}

export default App;
