import { combineReducers } from 'redux';
import customers from 'lib/Customer/reducers/customers';
import leads from 'lib/Customer/reducers/leads';
import organizations from 'lib/Customer/reducers/organizations';
import staffs from 'lib/Customer/reducers/staffs';
import sales from 'lib/Sales/reducers/sales';

export default combineReducers({
  customers, leads, staffs, organizations, sales,
});
