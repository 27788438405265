import { getDefaultOrganizationsFilter } from 'lib/Customer/services/organizations';

import {
  RECEIVE_ORGANIZATIONS,
} from 'lib/Customer/actions/organizations';

import {
  ORG_ADD_TAB_HEAD,
  ORG_REMOVE_TAB_HEAD,
  ORG_UPDATE_ACTIVE_TAB,
  ORG_ADD_TAB_DATA,
  UPDATE_ORGANIZATIONS_DATA,
  RECEIVE_USER_DETAIL,
} from 'lib/Customer/constants/action-types';

import { API_END, API_START } from 'lib/common/actions/types';

const initialState = {
  activeTab: 'organizations',
  activeTabData: {},
  existedTabHead: {},
  existedTabData: {},
  // TODO: move it to OrganizationView Reducer
  users: {},

  dashboard: {
    organizations: [],
    filter: getDefaultOrganizationsFilter(),
    order: { createdAt: 'desc' },
    pagination: {
      page: 1,
      perPage: 10,
    },
    total: 0,
    loading: false,
  },
};

function organizations(state = initialState, action) {
  // previous state
  const tabData = state.existedTabData;
  const tabHead = state.existedTabHead;
  const { [action.data]: value, ...withoutData } = tabHead;

  switch (action.type) {
    case ORG_UPDATE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };
    case ORG_ADD_TAB_DATA:
      tabData[action.data.index] = action.data.data;
      return {
        ...state,
        existedTabData: tabData,
      };
    case ORG_ADD_TAB_HEAD:
      return {
        ...state,
        existedTabHead: {
          ...state.existedTabHead,
          [action.data.id]: action.data,
        },
      };
    case ORG_REMOVE_TAB_HEAD:
      // data is a index when call to remove.
      return {
        ...state,
        existedTabHead: withoutData,
      };

    case API_START:
      if (action.payload === 'organizations') {
        return {
          ...state,
          dashboard: { ...state.dashboard, loading: true },
        };
      }
      break;
    case API_END:
      if (action.payload === 'organizations') {
        return {
          ...state,
          dashboard: { ...state.dashboard, loading: false },
        };
      }
      break;
    case RECEIVE_ORGANIZATIONS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          organizations: action.organizations,
          total: action.total,
        },
      };
    case UPDATE_ORGANIZATIONS_DATA:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          filter: action.request.filter,
          pagination: action.request.pagination,
          order: action.request.order,
        },
      };
    case RECEIVE_USER_DETAIL:
      const { activeTab, users } = state;
      const _users = { ...users };
      _users[activeTab] = action.user;

      return {
        ...state,
        users: _users,
      };
    default:
      return state;
  }

  return state;
}

export default organizations;
