import { apiAction } from 'lib/common/middleware/backOfficeApiMiddleware';
import { getStaffsRequest } from 'lib/Customer/services/staffs';

export const RECEIVE_STAFFS = 'RECEIVE_STAFFS';
export const UPDATE_DASHBOARD_REQUEST = 'UPDATE_STAFF_DASHBOARD_REQUEST';
export const UPDATE_DASHBOARD_MODAL = 'UPDATE_DASHBOARD_MODAL';

function receiveStaffs(data) {
  return {
    type: RECEIVE_STAFFS,
    staffs: data['hydra:member'],
    total: data['hydra:totalItems'],
  };
}

export function fetchStaffs(request) {
  const { filter, pagination, order } = request;

  return apiAction(
    {
      request: getStaffsRequest(filter, pagination, order),
      onSuccess: receiveStaffs,
      label: 'staffs',
    },
  );
}

export function updateRequest(request) {
  return {
    type: UPDATE_DASHBOARD_REQUEST,
    request,
  };
}

export function updateModal(modal) {
  return {
    type: UPDATE_DASHBOARD_MODAL,
    modal,
  };
}
