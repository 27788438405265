export const ADD_TAB = 'CUSTOMER_COMPLAINT_ADD_TAB';
export const REMOVE_TAB = 'CUSTOMER_COMPLAINT_REMOVE_TAB';
export const ACTIVATE_TAB = 'CUSTOMER_COMPLAINT_ACTIVATE_TAB';

export function addTab(tabCode, tabLabel, tabProps) {
  return {
    type: ADD_TAB,
    tabCode,
    tabLabel,
    tabProps,
  };
}

export function removeTab(tabCode) {
  return {
    type: REMOVE_TAB,
    tabCode,
  };
}

export function activateTab(tabCode) {
  return {
    type: ACTIVATE_TAB,
    tabCode,
  };
}
