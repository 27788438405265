const API_PPC_INSTANCE_QUERY = 'price-instance/query';
const API_PPC_INSTANCE_PUSH = 'price-instance/push';

export const getPPCInstance = () => ({
  service: 'ppc_service',
  method: 'GET',
  path: API_PPC_INSTANCE_QUERY,
  params: {},
});

export const pushPriceToPPCInstance = (PPCCode) => ({
  service: 'ppc_service',
  method: 'POST',
  path: API_PPC_INSTANCE_PUSH,
  params: {
    code: PPCCode,
    start: true,
  },
});

export const checkPriceIndexProgress = (PPCCode) => ({
  service: 'ppc_service',
  method: 'POST',
  path: API_PPC_INSTANCE_PUSH,
  params: {
    code: PPCCode,
  },
});
