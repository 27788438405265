import {
  RECEIVE_CLIENTS,
  RECEIVE_COUNTRIES,
  RECEIVE_DASHBOARD_USERS,
  RECEIVE_LOOKUP_USERS, RECEIVE_ROLES,
  UPDATE_DASHBOARD_REQUEST,
} from '../actions';
import { getDashboardDefaultFilter } from '../services/users';

const initialState = {
  lookup: [],
  roles: [],
  clients: [],
  countries: [],
  dashboard: {
    users: [],
    filter: getDashboardDefaultFilter(),
    order: { username: 'ASC' },
    pagination: {
      page: 1,
      perPage: 10,
    },
    total: 0,
    loading: false,
  },
};

function users(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_LOOKUP_USERS:
      return {
        ...state,
        lookup: action.users,
      };
    case UPDATE_DASHBOARD_REQUEST:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          filter: action.request.filter,
          pagination: action.request.pagination,
          order: action.request.order,
        },
      };
    case RECEIVE_DASHBOARD_USERS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          users: action.users,
          total: action.total,
        },
      };
    case RECEIVE_ROLES:
      return {
        ...state,
        roles: action.roles,
      };
    case RECEIVE_COUNTRIES:
      return {
        ...state,
        countries: action.countries,
      };
    case RECEIVE_CLIENTS:
      return {
        ...state,
        clients: action.clients,
      };
    default:
      return state;
  }
}

export default users;
