function isSupportCompress() {
  return typeof CompressionStream === 'function';
}

async function compress(string, encoding = 'gzip') {
  const byteArray = new TextEncoder().encode(string);
  // eslint-disable-next-line no-undef
  const cs = new CompressionStream(encoding);
  const writer = cs.writable.getWriter();
  writer.write(byteArray);
  writer.close();
  const arrayBuffer = await new Response(cs.readable).arrayBuffer();
  return arrayBuffer;
}

async function decompress(byteArray, encoding = 'gzip') {
  // eslint-disable-next-line no-undef
  const cs = new DecompressionStream(encoding);
  const writer = cs.writable.getWriter();
  writer.write(byteArray);
  writer.close();
  const arrayBuffer = await new Response(cs.readable).arrayBuffer();
  return new TextDecoder().decode(arrayBuffer);
}

export {
  isSupportCompress,
  compress,
  decompress,
};
