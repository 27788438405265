import { getSSHTask, createSSHTask } from 'lib/M2SSHService/request';
import { apiAction } from 'lib/common/middleware/backOfficeApiMiddleware';

export const RECEIVE_SSH_TASKS = 'RECEIVE_SSH_TASKS';
export const GET_SSH_TASKS = 'GET_SSH_TASKS';
export const CREATE_SSH_TASK = 'CREATE_SSH_TASK';
export const OPEN_CREATE_SSH_TASK_MODAL = 'OPEN_CREATE_SSH_TASK_MODAL';
export const CLOSE_CREATE_SSH_TASK_MODAL = 'CLOSE_CREATE_SSH_TASK_MODAL';
export const UPDATE_CREATE_SSH_TASK_MODAL = 'UPDATE_CREATE_SSH_TASK_MODAL';
export const TOGGLE_AUTO_RELOAD = 'TOGGLE_AUTO_RELOAD';

function receiveSSHTasks(data) {
  return {
    type: RECEIVE_SSH_TASKS,
    items: data.items,
    total: data.items.length,
  };
}

export function fetchSSHTasks() {
  return (dispatch) => {
    dispatch({ type: GET_SSH_TASKS });
    dispatch(apiAction({
      request: getSSHTask(),
      onSuccess: receiveSSHTasks,
      label: 'm2-ssh-service',
    }));
  };
}

export function createSSHTask2(request) {
  return (dispatch) => {
    dispatch({ type: CLOSE_CREATE_SSH_TASK_MODAL });
    dispatch({ type: GET_SSH_TASKS });
    dispatch(apiAction({
      request: createSSHTask(request.env, request.publicKey, request.expireAfter),
      onSuccess: fetchSSHTasks,
      label: 'm2-ssh-service',
    }));
  };
}

export function openCreateSSHTaskMODAL() {
  return (dispatch) => {
    dispatch({ type: OPEN_CREATE_SSH_TASK_MODAL });
  };
}

export function toggleAutoReload() {
  return (dispatch) => {
    dispatch({ type: TOGGLE_AUTO_RELOAD });
  };
}

export function closeCreateSSHTaskMODAL() {
  return (dispatch) => {
    dispatch({ type: CLOSE_CREATE_SSH_TASK_MODAL });
  };
}

export function updateCreateSSHTaskMODAL(data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_CREATE_SSH_TASK_MODAL, data });
  };
}
