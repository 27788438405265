import { apiAction } from 'lib/common/middleware/backOfficeApiMiddleware';
import { getProductionItemRequest } from 'lib/Production/services/production';

export const RECEIVE_SINGLEJOB_ITEMS_RESPONSE = 'RECEIVE_SINGLEJOB_ITEMS_RESPONSE';
export const UPDATE_SINGLEJOB_REQUEST = 'UPDATE_SINGLEJOB_REQUEST';

const receiveSingleItems = (country) => (data) => ({
  type: RECEIVE_SINGLEJOB_ITEMS_RESPONSE,
  country,
  items: data['hydra:member'],
  total: data['hydra:totalItems'],
});

export function fetchSingleItems({
  filter, pagination, order, country,
}) {
  return apiAction(
    {
      request: getProductionItemRequest({
        ...filter,
        workflowName: 'production_item',
        country,
      }, pagination, order),
      onSuccess: receiveSingleItems(country),
      label: `singlejob.${country}`,
    },
  );
}

export function updateSingleRequest(request) {
  return {
    ...request,
    type: UPDATE_SINGLEJOB_REQUEST,
  };
}
