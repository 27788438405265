import { apiAction } from 'lib/common/middleware/backOfficeApiMiddleware';
import {
  getComplaintRequest,
  getComplaintCommentRequest,
  getComplaintCreateRequest,
  getComplaintUpdateRequest,
  getComplaintCommentUpdateRequest,
  getComplaintCommentVersionRequest,
  getSingleComplaintRequest,
} from 'lib/CustomerComplaint/services/complaint';

export const RECEIVE_COMPLAINTS_RESPONSE = 'RECEIVE_COMPLAINTS_RESPONSE';
export const RECEIVE_COMPLAINT_COMMENT_RESPONSE = 'RECEIVE_COMPLAINT_COMMENT_RESPONSE';
export const RECEIVE_OLDER_COMPLAINT_COMMENT_RESPONSE = 'RECEIVE_OLDER_COMPLAINT_COMMENT_RESPONSE';
export const UPDATE_COMPLAINTS_REQUEST = 'UPDATE_COMPLAINTS_REQUEST';
export const CREATE_COMPLAINT = 'CREATE_COMPLAINT';
export const RECIEVE_EDITED_COMMENT = 'RECIEVE_EDITED_COMMENT';
export const RECIEVE_EDITED_COMPLAINT = 'RECIEVE_EDITED_COMPLAINT';
export const OPEN_EDIT_COMPLAINT = 'OPEN_EDIT_COMPLAINT';
export const OPEN_COMPLAINT_TAB = 'OPEN_COMPLAINT_TAB';
export const RECIEVE_COMPLAINT = 'RECIEVE_COMPLAINT';

function receiveComplaints(data) {
  return {
    type: RECEIVE_COMPLAINTS_RESPONSE,
    complaints: data['hydra:member'],
    total: data['hydra:totalItems'],
  };
}

function receiveEditedComment(complaint) {
  return (editedComment) => ({
    type: RECIEVE_EDITED_COMMENT,
    tabCode: `singleComplaintView_${complaint.id}`,
    editedComment,
  });
}

function receiveComplaintComments(newTabCode, newTabLabel, complaint, type) {
  return (complaintComments) => ({
    type,
    complaintComments: {
      items: complaintComments['hydra:member'],
      total: complaintComments['hydra:totalItems'],
    },
    complaint,
    newTabCode,
    newTabLabel,
  });
}

function receiveComplaint(complaint) {
  return {
    type: RECIEVE_COMPLAINT,
    tabCode: `singleComplaintView_${complaint.id}`,
    complaint,
  };
}

export function fetchComplaints(request) {
  const { filter, pagination, order } = request;

  return apiAction(
    {
      request: getComplaintRequest(filter, pagination, order),
      onSuccess: receiveComplaints,
      label: 'customerComplaint',
    },
  );
}

export function fetchComplaintComments(complaint, createdAtBefore = null) {
  const id = typeof complaint === 'string' || complaint instanceof String ? complaint : complaint.id;

  const newTabCode = `singleComplaintView_${id}`;

  const EVENT_TYPE = createdAtBefore === null
    ? RECEIVE_COMPLAINT_COMMENT_RESPONSE : RECEIVE_OLDER_COMPLAINT_COMMENT_RESPONSE;

  const onSuccess = typeof complaint === 'string' || complaint instanceof String
    ? receiveComplaintComments(newTabCode, undefined, undefined, EVENT_TYPE)
    : receiveComplaintComments(newTabCode, complaint.incrementId, complaint, EVENT_TYPE);

  return apiAction(
    {
      request: getComplaintCommentRequest(id, createdAtBefore),
      onSuccess,
      label: 'customerComplaintComment',
    },
  );
}

export function editComment(complaint, commentId, text) {
  const data = text === '' ? { comment: { deleted: true } } : { comment: { text } };
  return apiAction(
    {
      request: getComplaintCommentUpdateRequest(commentId, data),
      onSuccess: receiveEditedComment(complaint),
      label: 'customerComplaintCommentEdit',
    },
  );
}

export function updateRequest(request) {
  return {
    type: UPDATE_COMPLAINTS_REQUEST,
    request,
  };
}

function receiveComplaintUpdated(complaint) {
  return fetchComplaintComments(complaint);
}

function receiveComplaintEdited(complaint) {
  return {
    type: RECIEVE_EDITED_COMPLAINT,
    complaint,
    tabCode: `singleComplaintView_${complaint.id}`,
  };
}

export function createComplaint(data) {
  return apiAction(
    {
      request: getComplaintCreateRequest(data),
      onSuccess: receiveComplaint,
      label: 'customerComplaintCreate',
    },
  );
}

export function updateComplaint(complaintId, data) {
  return apiAction(
    {
      request: getComplaintUpdateRequest(complaintId, data),
      onSuccess: receiveComplaintUpdated,
      label: 'customerComplaintUpdate',
    },
  );
}

export function editComplaint(complaintId, data) {
  return apiAction(
    {
      request: getComplaintUpdateRequest(complaintId, data),
      onSuccess: receiveComplaintEdited,
      label: 'customerComplaintEdit',
    },
  );
}

export function openEditComplaint(complaint) {
  return {
    type: OPEN_EDIT_COMPLAINT,
    tabCode: `singleComplaintView_${complaint.id}`,
  };
}

export function fetchComplaintCommentsHistory(commentId, createdAtBefore = null) {
  return apiAction(
    {
      request: getComplaintCommentVersionRequest(commentId, createdAtBefore),
      onSuccess: () => {},
      label: 'customerComplaintCommentVersion',
    },
  );
}

export function addComplaintTab(complaintId, complaint) {
  return {
    type: OPEN_COMPLAINT_TAB,
    tabCode: `singleComplaintView_${complaintId}`,
    complaintId,
    complaint,
  };
}

export function fetchSingleComplaint(complaintId) {
  return apiAction(
    {
      request: getSingleComplaintRequest(complaintId),
      onSuccess: receiveComplaint,
      label: 'customerSingleComplaint',
    },
  );
}
