import { getUsersByRolesRequest } from 'lib/User/services/users';
import { getLeadsRequest } from 'lib/Customer/services/leads';
import { apiAction } from '../../common/middleware/backOfficeApiMiddleware';

export const RECEIVE_LEADS = 'RECEIVE_LEADS';
export const RECEIVE_LEAD_USERS = 'RECEIVE_LEAD_USERS';
export const REQUEST_LEAD_USERS = 'REQUEST_LEAD_USERS';
export const UPDATE_FILTER = 'UPDATE_LEADS_FILTER';
export const UPDATE_DASHBOARD_REQUEST = 'UPDATE_LEADS_DASHBOARD_REQUEST';

export const REQUEST_LEAD_COMMENT = 'REQUEST_LEAD_COMMENT';
export const RECEIVE_LEAD_COMMENT = 'RECEIVE_LEAD_COMMENT';

function receiveLeads(data) {
  return {
    type: RECEIVE_LEADS,
    leads: data['hydra:member'],
    total: data['hydra:totalItems'],
  };
}

export function updateRequest(request) {
  return {
    type: UPDATE_DASHBOARD_REQUEST,
    request,
  };
}

function receiveLeadUsers(data) {
  return {
    type: RECEIVE_LEAD_USERS,
    users: data['hydra:member'],
  };
}

export function fetchLeads(request) {
  const { filter, pagination, order } = request;

  return apiAction({
    request: getLeadsRequest(filter, pagination, order),
    onSuccess: receiveLeads,
    label: 'leads',
  });
}

export function fetchLeadUsers() {
  return apiAction({
    request: getUsersByRolesRequest(['ROLE_SALES', 'ROLE_CUSTOMER_SERVICE']),
    onSuccess: receiveLeadUsers,
    label: 'lead_users',
  });
}

// export function fetchLeadComment() {
//
//   return (dispatch) => {
//     dispatch(receiveLeadComment());
//     return getUsersByRoles(['ROLE_SALES', 'ROLE_CUSTOMER_SERVICE'])
//       .then(response => response.data['hydra:member'])
//       .then(comment => dispatch(requestLeadComment(comment)));
//   };
// }

export function updateFilter(filter) {
  return (dispatch) => {
    dispatch(fetchLeads(filter));
    return {
      type: UPDATE_FILTER,
      filter,
    };
  };
}
