import { AUTH_USER } from 'lib/auth/actions/index';

const initialState = {
  isAuthenticated: false,
};

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
}

function auth(state = initialState, action = null) {
  if (AUTH_USER === action.type) {
    const jwt = parseJwt(action.user.idToken.jwtToken);

    return {
      ...state,
      isAuthenticated: true,
      user_id: jwt.gogoprint_user_id,
      name: action.user.idToken.payload.name,
      email: action.user.idToken.payload.email,
      picture: action.user.idToken.payload.picture,
      token: action.user.idToken.jwtToken,
      expiry: action.user.idToken.payload.exp,
      roles: jwt.roles.split(','),
      countries: jwt.gogoprint_countries.split(','),
    };
  }

  return state;
}

export default auth;
