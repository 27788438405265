const exportOptions = (
  (options) => (Object.entries(options)
    .map(([disabled, coutries]) => Object.entries(coutries).map(([key, values]) => [
      ...(values.label && values.label !== '') ? [{
        label: values.label,
        value: key,
        disabled: true,
      }] : [],
    ].concat(
      values.list.map((item) => ({
        label: item,
        value: item,
        disabled: disabled === 'disabled',
      })),
    )).flat()).flat()));

const storeOptions = [
  { value: 'th_ggp', label: 'Thailand => Gogoprint' },
  { value: 'th_hs', label: 'Thailand => Hellosticker' },
  { value: 'th_otp', label: 'Thailand => Ontimeprint' },
  { value: 'my_ggp', label: 'Malaysia => Gogoprint' },
  { value: 'my_hs', label: 'Malaysia => Hellosticker' },
  { value: 'my_otp', label: 'Malaysia => Ontimeprint' },
  { value: 'sg_ggp', label: 'Singapore => Gogoprint' },
  { value: 'sg_hs', label: 'Singapore => Hellosticker' },
  { value: 'sg_otp', label: 'Singapore => Ontimeprint' },
];

export default {
  stores: [
    { key: 'th_', value: 'Thailand' },
    { key: 'my_', value: 'Malaysia' },
    { key: 'sg_', value: 'Singapore' },
  ],
  storeOptions,
  storeOptionsInclStg: storeOptions
    .map(({ label, value }) => ([{ label, value }, { value: `stg_${value}`, label: `[Staging] ${label}` }]))
    .flat(),
  category: exportOptions({
    enabled: {
      th: {
        list: [
          'AW - Adjustments not According to Customer Instructions',
          'AW - Closing not According to Job Properties',
          'AW - Missing Artwork Elements',
          'AW - Others',
          'AW - Wrong Color Conversion',
          'AW - Wrong Content',
          'AW - Wrong Size',
          'CU - Confirmed Online Proof by Accident',
          'CU - Late Delivery Caused by Late OP Confirmation',
          'CU - Others',
          'CU - Poor Original Artwork Quality',
          'CU - Unrealistical Expectations',
          'CU - Unrealistical Expectations RGB to CMYK',
          'CU - Wrong Order Specifications',
          'DS - Wrong Artwork',
          'LP - Late Delivery / Delay',
          'LP - Parcels (Entirely) Damaged',
          'LP - Parcels (Partially) Damaged',
          'LP - Shipping (Entirely) Lost',
          'LP - Shipping (Partially) Lost',
          'PO - Color Deviations',
          'PO - Cutting / Folding Deviations',
          'PO - Die-cut Quality / Deviation Issues',
          'PO - Missing Item of One Order',
          'PO - Other Quality Issues',
          'PO - Others',
          'PO - Wrong Quantity',
          'PO - Paper',
          'PO - Binding',
          'PO - Wrong Specs (paper, lamination, etc.)',
          'PP - Color Deviations',
          'PP - Color Misregister',
          'PP - Cutting / Folding Deviations',
          'PP - Other Quality Issues',
          'PP - Others',
          'PP - Prepress / CTP Issues',
          'PP - Smudges & Stains',
          'PP - Missing Front or Back',
          'PP - Paper',
          'PP - Binding',
          'PP - Wrong Specs (paper, lamination, etc.)',
          'PP - Wrong Quantity',

          'SA - External Miscommunication',
          'SA - Human Error',
          'SA - Lack of Knowledge',
          'SA - Others',
          'SA - Wrong Artwork File Provided',
          'SC - External Miscommunication',
          'SC - Others',
          'SC - Wrong Production Parameters',
          'SC - Address / Order mixup',
          'SY - Delayed / Stucking Job',
          'SY - Others',
          'Wrong Quantity',
          'SY - Wrong information on website',
        ],
      },
      my: {
        label: 'Maylasia/Singapore',
        prefix: '[MY/SG]',
        list: [
          '01 Product Launch team',
          '02 Customer ',
          '03 Artwork Closing team',
          '04 Sales team',
          '05 Customer service',
          '06 Supply Chain',
          '07 Supplier',
          '08 Logistic Partner',
          '09 Warehouse',
        ],
      },
    },
  }),
  problemSource: exportOptions({
    enabled: {
      th: {
        list: [
          'Artwork Team [AW]',
          'Customer [CU]',
          'Design team [DS]',
          'GGP Production [PO]',
          'Logistic Partner [LP]',
          'Printing Partner [PP]',
          'Sales Team [SA]',
          'Supply Chain [SC]',
          'System [SY]',
        ],
      },
    },
    disabled: {
      global: {
        label: '=== No longer use ===',
        prefix: '',
        list: [
          'GGP Post-Press [PO]',
          'Accounting ',
          'Artwork Closing',
          'Customer',
          'Customer Service',
          'Graphic Design',
          'Logistics Partner',
          'Logistics Team',
          'Marketing',
          'Printing Partner',
          'Production Team - Lab',
          'Sales Closing',
          'Supply Chain',
          'System',
          'Cutting deviations',
          'Colors deviations',
          'Missing Front or Back',
          'Missing Artwork Elements',
          'Missing Round Corner',
          'Poor Quality',
          'Wrong Quality',
          'Paper does not match to order',
        ],
      },
    },
  }),

  problemSourceMY: exportOptions({
    enabled: {
      my: {
        list: [
          'Artwork Team [AW]',
          'Customer [CU]',
          'Design team [DS]',
          'Logistic Partner [LP]',
          'Printing Partner [PP]',
          'Sales Team [SA]',
          'Supply Chain [SC]',
          'System [SY]',
        ],
      },
    },
  }),

  ggpAction: exportOptions({
    enabled: {
      th: {
        list: [
          'Apology Code',
          'Cancelled (duplicate, error, etc.)',
          'Discount',
          'Educate Customer',
          'Full Refund',
          'No Action Required',
          'Partial Refund',
          'Reprint Offline',
          'Reprint with GGP Cost',
          'Reprint with Supplier Cost',
        ],
      },
    },
    // disabled: {
    //   global: {
    //     label: '=== No longer use ===',
    //     prefix: '',
    //     list: [
    //       'Reprint',
    //       'Refund',
    //       'Discount Code',
    //       'Cancelled',
    //       'No Action',
    //     ],
    //   },
    // },
  }),
  comp: exportOptions({
    enabled: {
      global: {
        label: '',
        prefix: '',
        list: [
          'Insufficient',
          'Sufficient',
        ],
      },
    },
    disabled: {
      global: {
        label: '',
        prefix: '',
        list: [],
      },
    },
  }),
  ticketStatus: [
    { label: 'Open', value: 'open' },
    { label: 'Close', value: 'close' },
  ],
  defaultAssignUser: [
    { label: 'Varaporn Pitchathum', value: 'varaporn.p@gogoprint.co.th' },
    { label: 'Kamolchanok Showpitakvatana', value: 'kamolchanok.s@gogoprint.com' },
    { label: 'Rattana Amphet', value: 'rattana.a@gogoprint.co.th' },
  ],
};
