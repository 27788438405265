import config from 'config';

const authExport = {
  oauth: {
    domain: config.cognito.AUTH_HOST,
    scope: [
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: config.site.URL,
    redirectSignOut: config.site.URL,
    responseType: 'code', // or token
  },
};

const amplifyExport = {
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    ...authExport,
  },
};

export {
  amplifyExport,
  authExport,
};
