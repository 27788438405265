import {
  RECEIVE_INBOUND_ITEMS_RESPONSE,
  UPDATE_INBOUND_ITEMS_REQUEST,
} from 'lib/Shipping/actions/inbound';
import { API_END, API_START } from 'lib/common/actions/types';

import { defaultInboundFilter } from 'lib/Shipping/services/shipping';

const initialState = {
  inbound: {
    th: {
      items: [],
      filter: defaultInboundFilter('th'),
      order: { orderedAt: 'DESC' },
      pagination: {
        page: 1,
        perPage: 30,
      },
      total: 0,
      loading: false,
    },
    my: {
      items: [],
      filter: defaultInboundFilter('my'),
      order: { orderedAt: 'DESC' },
      pagination: {
        page: 1,
        perPage: 30,
      },
      total: 0,
      loading: false,
    },
  },
};

function inbound(state = initialState, action) {
  switch (action.type) {
    case API_START:
    case API_END:
      if (action.payload.startsWith('inbound')) {
        const country = action.payload.split('.')[1];
        return {
          ...state,
          inbound: {
            ...state.inbound,
            [country]: { ...state.inbound[country], loading: action.type === API_START },
          },
        };
      }
      break;
    case UPDATE_INBOUND_ITEMS_REQUEST:
      return {
        ...state,
        inbound: {
          ...state.inbound,
          [action.country]: {
            ...state.inbound[action.country],
            filter: action.filter,
            pagination: action.pagination,
            order: action.order,
          },
        },
      };
    case RECEIVE_INBOUND_ITEMS_RESPONSE:
      return {
        ...state,
        inbound: {
          ...state.inbound,
          [action.country]: {
            ...state.inbound[action.country],
            items: action.items,
            total: action.total,
          },
        },
      };
    default:
      return state;
  }

  return state;
}

export default inbound;
