import { getOrganizationsRequest } from 'lib/Customer/services/organizations';
import {
  ORG_ADD_TAB_HEAD,
  ORG_REMOVE_TAB_HEAD,
  ORG_UPDATE_ACTIVE_TAB,

  UPDATE_ORGANIZATIONS_DATA,
  UPDATE_ORGANIZATIONS_DATA_FILTER,
  RESET_ORGANIZATIONS_DATA_FILTER,
} from '../constants/action-types';

import { apiAction } from '../../common/middleware/backOfficeApiMiddleware';

export const RECEIVE_ORGANIZATIONS = 'RECEIVE_ORGANIZATIONS';
export const UPDATE_DASHBOARD_REQUEST = 'UPDATE_ORGANIZATIONS_DASHBOARD_REQUEST';

export function updateActiveTab(activeTab) {
  return {
    type: ORG_UPDATE_ACTIVE_TAB,
    activeTab,
  };
}

export function updateTabHead(method, data) {
  switch (method) {
    case 'ADD':
      return {
        type: ORG_ADD_TAB_HEAD,
        data,
      };

    case 'REMOVE':
      return {
        type: ORG_REMOVE_TAB_HEAD,
        data,
      };

    default:
      return null;
  }
}

export function updateOrganizations(data) {
  return { type: UPDATE_ORGANIZATIONS_DATA, data };
}

export function updateOrganizationsFilter(data) {
  return { type: UPDATE_ORGANIZATIONS_DATA_FILTER, data };
}

export function updateOrganizationsDataFilter(data) {
  return { type: UPDATE_ORGANIZATIONS_DATA_FILTER, data };
}

export function updateOrganizationsData(data) {
  return { type: UPDATE_ORGANIZATIONS_DATA, data };
}

export function resetItemsDataFilter() {
  return { type: RESET_ORGANIZATIONS_DATA_FILTER };
}

function receiveOrganizations(data) {
  return {
    type: RECEIVE_ORGANIZATIONS,
    organizations: data['hydra:member'],
    total: data['hydra:totalItems'],
  };
}

export function fetchOrganizations(request) {
  const { filter, pagination, order } = request;

  return apiAction({
    request: getOrganizationsRequest(filter, pagination, order),
    onSuccess: receiveOrganizations,
    label: 'organizations',
  });
}

export function updateRequest(request) {
  return {
    type: UPDATE_ORGANIZATIONS_DATA,
    request,
  };
}
