import { defaultLeadsFilter } from 'lib/Customer/services/leads';
import {
  REQUEST_ASSIGN_USER,
  RECEIVE_ASSIGN_USER,
  RECEIVE_LEAD_ACTIONS,
  REQUEST_LEAD_ACTIONS,
  RECEIVE_UPDATE_LEAD, RECEIVE_LEAD,
} from 'lib/Customer/actions/lead';
import {
  RECEIVE_LEADS,
  RECEIVE_LEAD_USERS,
  REQUEST_LEAD_USERS,
  UPDATE_DASHBOARD_REQUEST,
  REQUEST_LEAD_COMMENT,
  RECEIVE_LEAD_COMMENT,
} from 'lib/Customer/actions/leads';
import { API_END, API_START } from 'lib/common/actions/types';

const initialState = {
  dashboard: {
    leads: [],
    filter: defaultLeadsFilter(),
    order: { createdAt: 'desc' },
    pagination: {
      page: 1,
      perPage: 10,
    },
    total: 0,
    loading: false,
  },
  leadUsers: [],
  leadActions: [],
  isFetchingActions: false,
  isFetchingUsers: false,
  leadComment: [],
  isFetchingComment: false,
};

function leads(state = initialState, action) {
  switch (action.type) {
    case API_START:
      if (action.payload === 'leads') {
        return {
          ...state,
          dashboard: { ...state.dashboard, loading: true },
        };
      }
      break;
    case API_END:
      if (action.payload === 'leads') {
        return {
          ...state,
          dashboard: { ...state.dashboard, loading: false },
        };
      }
      break;
    case REQUEST_ASSIGN_USER:
      return { ...state, isAssigningUser: true };
    case RECEIVE_ASSIGN_USER:
      return {
        ...state,
        isAssigningUser: false,
        dashboard: {
          ...state.dashboard,
          leads: state.dashboard.leads.map(
            (lead) => ((lead.id === action.lead.id) ? action.lead : lead),
          ),
        },
      };
    case RECEIVE_LEAD:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          leads: state.dashboard.leads.map(
            (lead) => ((lead.id === action.lead.id) ? action.lead : lead),
          ),
        },
      };
    case RECEIVE_UPDATE_LEAD:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          leads: state.dashboard.leads.map(
            (lead) => ((lead.id === action.lead.id) ? action.lead : lead),
          ),
        },
      };
    case RECEIVE_LEADS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          leads: action.leads,
          total: action.total,
        },
      };
    case UPDATE_DASHBOARD_REQUEST:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          filter: action.request.filter,
          pagination: action.request.pagination,
          order: action.request.order,
        },
      };
    case REQUEST_LEAD_ACTIONS:
      return { ...state, isFetchingActions: true };
    case RECEIVE_LEAD_ACTIONS:
      return {
        ...state,
        isFetchingActions: false,
        leadActions: action.actions,
      };
    case REQUEST_LEAD_USERS:
      return { ...state, isFetchingUsers: true };
    case RECEIVE_LEAD_USERS:
      return { ...state, isFetchingUsers: false, leadUsers: action.users };
    case REQUEST_LEAD_COMMENT:
      return { ...state, isFetchingComment: true };
    case RECEIVE_LEAD_COMMENT:
      return { ...state, isFetchingComment: false, leadComment: action.comments };
    default:
      return state;
  }

  return state;
}

export default leads;
