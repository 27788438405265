let websocket = null;

export const WEBSOCKET_CONNECT = 'WEBSOCKET_CONNECT';
export const WEBSOCKET_OPEN = 'WEBSOCKET_OPEN';
export const WEBSOCKET_CLOSE = 'WEBSOCKET_CLOSE';
export const WEBSOCKET_MESSAGE = 'WEBSOCKET_MESSAGE';
export const WEBSOCKET_SEND = 'WEBSOCKET_SEND';
export const WEBSOCKET_DISCONNECT = 'WEBSOCKET_DISCONNECT';

const websocketMiddleware = ({ dispatch } = { dispatch: () => {} }) => (next) => async (action) => {
  if (!Object.prototype.hasOwnProperty.call(action, 'type')) {
    return next(action);
  }
  switch (action.type) {
    // User request to connect
    case WEBSOCKET_CONNECT:
      // Configure the object
      websocket = new WebSocket(action.payload.url);

      // Attach the callbacks
      websocket.onopen = () => dispatch({ type: WEBSOCKET_OPEN });
      websocket.onclose = (event) => dispatch({ type: WEBSOCKET_CLOSE, payload: event });
      websocket.onmessage = (event) => dispatch({ type: WEBSOCKET_MESSAGE, payload: event });

      break;

    // User request to send a message
    case WEBSOCKET_SEND:
      websocket.send(JSON.stringify(action.payload));
      break;

    // User request to disconnect
    case WEBSOCKET_DISCONNECT:
      if (websocket !== null) {
        websocket.close();
        websocket = null;
      }
      break;

    default: // We don't really need the default but ...
      break;
  }

  return next(action);
};

export default websocketMiddleware;
