import { apiAction } from '../../common/middleware/backOfficeApiMiddleware';
import {
  getAllUsersRequest,
  getClientsRequest,
  getCountriesRequest,
  getRolesRequest,
  getUsersRequest,
} from '../services/users';

export const RECEIVE_LOOKUP_USERS = 'RECEIVE_LOOKUP_USERS';
export const UPDATE_DASHBOARD_REQUEST = 'user/dashboard/UPDATE_REQUEST';
export const RECEIVE_DASHBOARD_USERS = 'user/dashboard/RECEIVE_USERS';
export const RECEIVE_ROLES = 'user/RECEIVE_ROLES';
export const RECEIVE_COUNTRIES = 'user/RECEIVE_COUNTRIES';
export const RECEIVE_CLIENTS = 'user/RECEIVE_CLIENTS';

const receiveLookupUsers = (data) => ({
  type: RECEIVE_LOOKUP_USERS,
  users: data['hydra:member'],
});

export const fetchLookupUsers = () => apiAction(
  {
    request: getAllUsersRequest(),
    onSuccess: receiveLookupUsers,
    label: 'fetch_lookup_users',
  },
);

export const updateDashboardRequest = (request) => ({
  type: UPDATE_DASHBOARD_REQUEST,
  request,
});

export const receiveDashboardUsers = (data) => ({
  type: RECEIVE_DASHBOARD_USERS,
  users: data['hydra:member'],
  total: data['hydra:totalItems'],
});

export const fetchDashboardUsers = (request) => {
  const { filter, pagination, order } = request;

  return apiAction(
    {
      request: getUsersRequest(filter, pagination, order),
      onSuccess: receiveDashboardUsers,
      label: 'user/dashboard',
    },
  );
};

export const receiveRoles = (data) => ({
  type: RECEIVE_ROLES,
  roles: data['hydra:member'],
});

export const receiveCountries = (data) => ({
  type: RECEIVE_COUNTRIES,
  countries: data['hydra:member'],
});

export const receiveClients = (data) => ({
  type: RECEIVE_CLIENTS,
  clients: data['hydra:member'],
});

export const fetchRoles = () => apiAction(
  {
    request: getRolesRequest(),
    onSuccess: receiveRoles,
    label: 'user/roles',
  },
);

export const fetchCountries = () => apiAction(
  {
    request: getCountriesRequest(),
    onSuccess: receiveCountries,
    label: 'user/countries',
  },
);

export const fetchClients = () => apiAction(
  {
    request: getClientsRequest(),
    onSuccess: receiveClients,
    label: 'user/clients',
  },
);
