import {
  RECEIVE_S3_RESTORE_TASKS,
  GET_S3_RESTORE_TASKS,
  UPDATE_S3_RESTORE_TASK_REQUEST,
} from './actions';

const initialState = {
  dashboard: {
    items: [],
    filter: {},
    order: { createdAt: 'desc' },
    pagination: {
      page: 1,
      perPage: 10,
    },
    loading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_S3_RESTORE_TASKS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loading: true,
        },
      };
    case RECEIVE_S3_RESTORE_TASKS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          items: action.items,
          loading: false,
          paginationData: {
            ...state.dashboard.paginationData,
            [state.dashboard.pagination.perPage]: {
              ...state.dashboard.paginationData?.[state.dashboard.pagination.page],
              [`page_${state.dashboard.pagination.page}`]: action.nextPage,
            },
          },
        },
      };
    case UPDATE_S3_RESTORE_TASK_REQUEST:

      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          pagination: {
            page: action.request.pagination.page,
            perPage: action.request.pagination.perPage,
          },
        },
      };
    default:
      return state;
  }
};
