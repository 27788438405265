import BackOfficeUtils from 'lib/BackOfficeUtils';
import config from 'lib/OnlinePreflight/config';

export function defaultFilter() {
  return {
    page: 1,
    perPage: 10,
    created: { before: '', after: '' },
    status: [],
    type: [],
    sort: 'created_date',
    country: [],
    customer_id: '',
    request_ip: '',
  };
}

export const getSelectOptions = (data) => data.reduce(
  (options, status) => {
    options.push({ value: status.key, label: status.value });
    return options;
  },
  [],
);

export const getStatusSelect = () => {
  const { statuses } = config;

  return getSelectOptions(statuses);
};

export const getTypeSelect = () => {
  const { types } = config;

  return getSelectOptions(types);
};

export async function getPreflights(filter) {
  return BackOfficeUtils.request(
    'preflight',
    {
      method: 'GET',
      path: '/preflightRequest',
      params: BackOfficeUtils.cleanFilter(filter),
    },
  ).then((result) => result.data);
}

export async function getPreflight(preflightId) {
  return BackOfficeUtils.request(
    'preflight',
    {
      method: 'GET',
      path: `/preflightRequest/${preflightId}`,
    },
  ).then((result) => result.data);
}

export async function getPreflightStages(preflightId) {
  return BackOfficeUtils.request(
    'preflight',
    {
      method: 'GET',
      path: `/preflightRequest/${preflightId}/stage`,
    },
  ).then((result) => result.data);
}

export function getFilterQuery(filter) {
  let query = '?';
  Object.keys(filter).forEach((key) => {
    if (key === 'created') {
      if (filter[key].before && filter[key].after) {
        query += `created[before]=${filter[key].before}&`;
        query += `created[after]=${filter[key].after}&`;
      } else if (filter[key].before) {
        query += `created[before]=${filter[key].before}&`;
      } else if (filter[key].after) {
        query += `created[after]=${filter[key].after}&`;
      }
    } else if (key === 'status' || key === 'country') {
      for (let i = 0; i < filter[key].length; i++) {
        if (i < filter[key].length - 1) {
          query += `${key}[]=${filter[key][i]}&`;
        } else {
          query += `${key}[]=${filter[key][i]}&`;
        }
      }
    } else if (key === 'page') {
      query += `page=${filter[key]}&`;
    } else if (key === 'perPage') {
      query += `perPage=${filter[key]}&`;
    } else if (key === 'sort') {
      query += `sort=${filter[key]}&`;
    } else if (filter[key]) {
      query += `${key}=${filter[key]}&`;
    }
  });

  return query;
}

export function getMagentoFolderPath(preflightId, country) {
  return `${process.env[`REACT_APP_GOGO_${country}_URL`]}data/preflight/requests/${preflightId}`;
}

export function getPreflightImgPath(preflightId, file, country) {
  return `${process.env[`REACT_APP_GOGO_${country}_URL`]}data/preflight/requests/${preflightId}/previews/${file}`;
}
