import {
  RECEIVE_PPC_INSTANCES,
  OPEN_REINDEX_MODAL,
  CLOSE_REINDEX_MODAL,
  START_INDEXING,
  REINDEX_FINISHED,
} from 'lib/PPCService/actions';

import { WEBSOCKET_MESSAGE } from 'lib/common/middleware/websocketMiddleware';

const initialState = {
  dashboard: {
    modal: null,
    items: [],
    filter: {},
    order: { createdAt: 'desc' },
    pagination: {
      page: 1,
      perPage: 10,
    },
    total: 0,
    loading: false,
  },
};

export default (state = initialState, action) => {
  const allDoneResponse = (time) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      modal: {
        ...state.dashboard.modal,
        reindexProgress: {
          ...state.dashboard.modal.reindexProgress,
          updatedAt: time,
          totalPercentage: 100,
          taskPercentage: 100,
          taskName: 'Indexing Finished',
        },
      },
    },
  });

  switch (action.type) {
    case RECEIVE_PPC_INSTANCES:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          total: action.total,
          items: action.items,
        },
      };
    case OPEN_REINDEX_MODAL:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          modal: {
            ...action.priceInstance,
            reindexProgress: {
              totalPercentage: 0,
              taskPercentage: 0,
              taskName: 'Waiting Reindex Progress',
              updatedAt: 0,
            },
          },
        },
      };
    case REINDEX_FINISHED:
      return allDoneResponse(Date.now());
    case CLOSE_REINDEX_MODAL:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          modal: null,
        },
      };
    case START_INDEXING:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          modal: {
            ...state.dashboard.modal,
            state: 'indexing',
          },
        },
      };
    case WEBSOCKET_MESSAGE:
      // eslint-disable-next-line no-case-declarations
      const data = JSON.parse(action.payload.data);
      if (
        data.message.type === 'progressUpdate'
        && data.time < state.dashboard.modal.reindexProgress.updatedAt
      ) {
        return state;
      }
      switch (data.message.type) {
        case 'progressUpdate':
          return {
            ...state,
            dashboard: {
              ...state.dashboard,
              modal: {
                ...state.dashboard.modal,
                reindexProgress: {
                  ...state.dashboard.modal.reindexProgress,
                  updatedAt: data.time,
                  totalPercentage: parseFloat(data.message.data.totalPercentage),
                  taskPercentage: parseFloat(data.message.data.taskPercentage),
                  taskName: data.message.data.taskName,
                },
              },
            },
          };
        case 'progressFinished':
          return allDoneResponse(data.time);
        case 'exception':
          return {
            ...state,
            dashboard: {
              ...state.dashboard,
              modal: {
                ...state.dashboard.modal,
                reindexProgress: {
                  ...state.dashboard.modal.reindexProgress,
                  exception: data.message.data,
                },
              },
            },
          };
        default:
          return state;
      }
    default:
      return state;
  }
};
