import BackOfficeUtils from 'lib/BackOfficeUtils';
import { titleCase } from 'change-case';

export const getDashboardDefaultFilter = () => ({
  showInactive: false,
  search: null,
  country: [],
});

export function getUsersRequest(filter, pagination, order) {
  const params = {
    _page: pagination.page,
    _perPage: pagination.perPage,
    _order: order,
  };

  if (filter.search) {
    if (filter.search.text && filter.search.text !== '') {
      params[filter.search.type] = filter.search.text;
    }
  }

  if (filter.country.length > 0) {
    params['countries.code'] = filter.country;
  }

  if (!filter.showInactive) {
    params.active = true;
  }

  return {
    service: 'user', method: 'GET', path: 'api/users', params,
  };
}

export const getNewUser = () => ({
  id: null,
  active: false,
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  roles: [],
  clients: [],
  countries: [],
});

export const getUserName = (id, users) => {
  const user = users.find(
    (currentUser) => parseInt(id, 10) === currentUser.id,
  );

  if (!user) {
    return null;
  }

  return titleCase(`${user.firstName} ${user.lastName}`);
};

export const getUsersByStore = (store, users) => users.filter((user) => user.countries.findIndex(
  (country) => country.code.toLowerCase() === store.toLowerCase(),
) !== -1);

export const getAllUsersRequest = () => ({
  service: 'user',
  method: 'GET',
  path: 'api/users',
  params: {
    _pagination: false,
  },
});

export const getUsersByRolesRequest = (roles) => ({
  service: 'user',
  method: 'GET',
  path: 'api/users',
  params: {
    _pagination: false,
    'roles.code': roles,
    active: true,
  },
});

export const getUsersByIds = (ids) => BackOfficeUtils.processRequest(
  'user',
  {
    method: 'GET',
    path: 'api/users',
    params: {
      id: ids,
      _pagination: false,
    },
  },
).then((data) => data.items);

export const getUsersByFirstName = (name, active = true) => BackOfficeUtils.processRequest(
  'user',
  {
    method: 'GET',
    path: 'api/users',
    params: {
      firstName: name,
      active,
    },
  },
).then((data) => data.items);

export const getUsersByEmail = (email) => BackOfficeUtils.processRequest(
  'user',
  {
    method: 'GET',
    path: 'api/users',
    params: {
      email,
    },
  },
).then((data) => data.items);

export const getUsersByRoles = (roles) => {
  const request = getUsersByRolesRequest(roles);

  return BackOfficeUtils.request(request.service, request);
};

export const getRolesRequest = () => ({
  service: 'user',
  method: 'GET',
  path: 'api/roles',
  params: {
    _pagination: false,
  },
});

export const getCountriesRequest = () => ({
  service: 'user',
  method: 'GET',
  path: 'api/countries',
  params: {
    _pagination: false,
  },
});

export const getClientsRequest = () => ({
  service: 'user',
  method: 'GET',
  path: 'api/clients',
  params: {
    _pagination: false,
  },
});

const getUserBody = (user) => ({
  active: user.active,
  firstName: user.firstName,
  lastName: user.lastName,
  username: user.username,
  email: user.email,
  phone: user.phone,
  clients: user.clients.map((client) => `/user/api/clients/${client}`),
  countries: user.countries.map((country) => `/user/api/countries/${country}`),
  roles: user.roles.map((role) => `/user/api/roles/${role}`),
});

export const addUser = (user) => BackOfficeUtils.request('user', {
  method: 'post',
  path: 'api/users',
  data: getUserBody(user),
});

export const updateUser = (user) => BackOfficeUtils.request('user', {
  method: 'put',
  path: `api/users/${user.id}`,
  data: getUserBody(user),
});
