import { apiAction } from 'lib/common/middleware/backOfficeApiMiddleware';
import { getShippingJobDashboardRequest } from 'lib/Shipping/services/shipping';

export const RECEIVE_SHIPPING_JOBS_RESPONSE = 'RECEIVE_SHIPPING_JOBS_RESPONSE';
export const UPDATE_SHIPPING_JOBS_REQUEST = 'UPDATE_SHIPPING_JOBS_REQUEST';

const receiveAllItems = (country) => (data) => ({
  type: RECEIVE_SHIPPING_JOBS_RESPONSE,
  country,
  items: data['hydra:member'],
  total: data['hydra:totalItems'],
});

export function fetchShippingJobs(request) {
  const {
    filter, pagination, order, country,
  } = request;
  return apiAction(
    {
      request: getShippingJobDashboardRequest({
        ...filter,
        country,
      }, pagination, order),
      onSuccess: receiveAllItems(country),
      label: `outbound-by-jobs.${country}`,
    },
  );
}

export function updateShippingJobsRequest(request) {
  return {
    ...request,
    type: UPDATE_SHIPPING_JOBS_REQUEST,
  };
}
