import BackOfficeUtils from 'lib/BackOfficeUtils';
import config from 'lib/Customer/config';
import { getUserName } from 'lib/User/services/users';

export const defaultLeadsFilter = () => ({
  assigned: null,
  search: null,
  store: [],
  status: [],
  quoteSource: [],
});

export const getSelectOptions = (data) => data.reduce(
  (options, option) => {
    options.push({ value: option.key, label: option.value });
    return options;
  },
  [],
);

export const getStatusSelect = () => {
  const { statuses } = config;

  return getSelectOptions(statuses);
};

export const getLeadSourcesSelect = () => {
  const { leadSources } = config;
  return getSelectOptions(leadSources);
};

/**
 * Get the name of an Assigned User
 * @param users
 * @param assigned
 * @returns {string}
 */
export const getAssignedName = (users, assigned) => {
  if (!assigned) {
    return 'Not Assigned';
  }

  const userName = getUserName(assigned, users);
  if (!userName) {
    return 'Not Assigned';
  }

  return userName;
};

export const getLeadsRequest = (filter, pagination, order) => {
  const params = {
    _page: pagination.page,
    _perPage: pagination.perPage,
    _order: order,
  };

  if (filter.store.length > 0) {
    params.store = filter.store;
  }

  if (filter.status.length > 0) {
    params.status = filter.status;
  }

  if (filter.quoteSource.length > 0) {
    params.quoteSource = filter.quoteSource;
  }

  if (filter.search) {
    params[filter.search.type] = filter.search.text;
  }

  return {
    service: 'customer', method: 'GET', path: 'api/leads', params,
  };
};

export const updateLead = (leadId, data) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'PUT',
    path: `api/leads/${leadId}`,
    data,
  },
);

export const assignUserToLead = (leadId, userId) => updateLead(
  leadId,
  { assignedTo: userId.toString() },
);

export const getLeadActions = (leadId) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'GET',
    path: `api/leads/${leadId}/actions`,
  },
).then((data) => data.actions);

export const getActionRequest = (leadId, action) => {
  const data = { action, id: leadId };

  return {
    service: 'customer',
    method: 'post',
    path: `api/leads/${leadId}/actions/${action}`,
    data,
  };
};

export const addLeadComment = (data) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'POST',
    path: 'api/lead_comments',
    data,
  },
);

export const getLeadComment = (leadId) => BackOfficeUtils.processRequest(
  'customer',
  {
    method: 'GET',
    path: `api/leads/${leadId}/comments`,
  },
).then((data) => data.comments);
