export default {
  stores: [
    { key: 'th_', value: 'Thailand' },
    { key: 'my_', value: 'Malaysia' },
    { key: 'sg_', value: 'Singapore' },
    { key: 'th_ggp', value: 'Thailand : GGP' },
    { key: 'th_hs', value: 'Thailand : HS' },
    { key: 'th_otp', value: 'Thailand : OTP' },
    { key: 'my_ggp', value: 'Malaysia : GGP' },
    { key: 'my_hs', value: 'Malaysia : HS' },
    { key: 'my_otp', value: 'Malaysia : OTP' },
    { key: 'sg_ggp', value: 'Singapore : GGP' },
    { key: 'sg_hs', value: 'Singapore : HS' },
    { key: 'sg_otp', value: 'Singapore : OTP' },
    { key: 'stg_', value: 'Staging' },
  ],

  stores_lang: [
    { key: 'th_2', value: 'Th' },
    { key: 'th_1', value: 'En' },
    { key: 'th_8', value: 'OTP:Th' },
    { key: 'th_9', value: 'OTP:En' },
    { key: 'th_4', value: 'HS:Th' },
    { key: 'th_5', value: 'HS:En' },
    { key: 'my_1', value: 'En' },
    { key: 'my_2', value: 'En' },
    { key: 'my_3', value: 'En' },
    { key: 'my_23', value: 'OTP:En' },
    { key: 'my_26', value: 'OTP:En' },
    { key: 'my_19', value: 'HS:En' },
    { key: 'my_21', value: 'HS:En' },
  ],
};
