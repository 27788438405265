import config from 'lib/ItemState/config';
import BackOfficeUtils from 'lib/BackOfficeUtils';

const API_QUERY_ORDER = 'api/orders-view';
const API_QUERY_ORDER_ITEM = 'api/orders-items-view';
const API_QUERY_ORDER_AGGREGATE = 'api/orders';
const API_UPDATE_ORDER_ADDRESS = 'api/order/update-address';
const API_UPDATE_ORDER_ITEM_DEADLINE = 'api/order-item/update-deadline';
const API_GET_ORDER_ITEM_ACTIONS = 'api/order-item-actions';
const API_PROOF_REQUEST = 'api/order-item/proof-request';
const API_PROOF_CANCEL = 'api/order-item/proof-cancel';
const API_PROOF_FEEDBACK = 'api/order-item/online-proof-feedback';
const API_CHANGE_FILETYPE = 'api/order/change-filetype';
const API_REMOVE_ARTWORK = 'api/order/remove-artwork';
const API_REMOVE_UPLOAD_ARTWORK = 'api/order/remove-upload-artwork';
const API_SKIP_UPLOAD_TIMER = 'api/order/skip-upload-timer';
const API_FORCE_START_PREPRESS = 'api/order/force-start-prepress';
const API_UPLOAD_ARTWORK = 'api/order/upload-artwork';
const API_ADD_SUPPLIER_QUOTATION = 'api/order/add-supplier-quotation';
const API_REMOVE_SUPPLIER_QUOTATION = 'api/order/remove-supplier-quotation';
const API_ADD_CUST_PO = 'api/order/add-customer-purchasing-order';
const API_REMOVE_CUST_PO = 'api/order/remove-customer-purchasing-order';
const API_CANCEL_ORDER = 'api/order/cancel';
const API_HOLD_ORDER = 'api/order/hold';
const API_UNHOLD_ORDER = 'api/order/unhold';
const API_ORDER_COMMENTS = 'api/order-comments';
const API_GET_ORDER_ITEM_HISTORY = 'api/order-item-history';
const API_GET_ORDER_HISTORY = 'api/order-history-payload';
const API_UPDATE_ORDER_ITEM_PRINT_CONFIG = 'api/order-item/update-print-config';
const API_UPDATE_ORDER_ITEM_PROMO_CONFIG = 'api/order-item/update-promo-config';
const API_PREPRESS_VIEW_ITEM = 'api/prepress/view-order-item';
const API_GET_PRINTQ_NEW_OPTIONS = 'price-instance/get-metadata';
const API_REPLAY_EXTERNAL = 'api/order/external-replay';
const API_TOPUP_RESOLVE = 'api/order/topup-resolve';
const API_TOPUP_CANCEL = 'api/order/topup-cancel';
const API_CHECK_DEADLINE = 'api/order-item/check-deadline';

export const getOrderDefaultFilter = () => ({
  incrementId: [],
  'items.itemId': [],
  store: [],
  salesPerson: [],
  'billingAddress.firstName': [],
  'shippingAddress.firstName': [],
  'billingAddress.lastName': [],
  'shippingAddress.lastName': [],
  'billingAddress.tel': [],
  'shippingAddress.tel': [],
  'billingAddress.postalCode': [],
  'shippingAddress.postalCode': [],
  'billingAddress.organisationName': [],
  'shippingAddress.organisationName': [],
  'billingAddress.vatId': [],
  'shippingAddress.vatId': [],
});

export const getOrderRequest = (filter, pagination, order) => {
  const params = {
    _page: pagination.page,
    _perPage: pagination.perPage,
    _order: order,
  };

  if (filter.incrementId && filter.incrementId.length > 0) {
    params.incrementId = filter.incrementId;
  }

  if (filter.store && filter.store.length > 0) {
    params.storeCode = filter.store;
  }

  if (filter['items.itemId'] != null && filter['items.itemId'].length > 0) {
    params['items.itemId'] = filter['items.itemId'];
  }

  if (filter.salesPerson != null && filter.salesPerson.length > 0) {
    params.salesPerson = filter.salesPerson;
  }

  ['billingAddress', 'shippingAddress'].forEach((type) => ([
    'firstName',
    'lastName',
    'organisationName',
    'vatId',
    'thoroughfare',
    'subThoroughfare',
    'locality',
    'subAdministrativeArea',
    'administrativeArea',
    'administrativeArea',
    'postalCode',
    'tel',
  ].forEach((fieldName) => {
    const name = `${type}.${fieldName}`;
    if (filter[name] != null && filter[name].length > 0) {
      params[name] = filter[name];
    }
  })));

  return {
    service: 'item_state_service', method: 'GET', path: API_QUERY_ORDER, params,
  };
};

export const getOrderItemsRequest = (orderId) => {
  const params = {
    _page: 1,
    _perPage: 25,
  };

  params.parent = encodeURIComponent(orderId);

  return {
    service: 'item_state_service', method: 'GET', path: API_QUERY_ORDER_ITEM, params,
  };
};

export const getOrderAggregateRequest = (orderId) => ({
  service: 'item_state_service', method: 'GET', path: `${API_QUERY_ORDER_AGGREGATE}/${encodeURIComponent(orderId)}`,
});

export const getSelectOptions = (data) => data.reduce(
  (options, option) => {
    options.push({ value: option.key, label: option.value });
    return options;
  },
  [],
);

export const getStoreSelect = () => {
  const { stores } = config;

  return getSelectOptions(stores);
};

export const updateAddress = async (orderId, store, addressType, newAddress) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_UPDATE_ORDER_ADDRESS,
    data: {
      orderId,
      store,
      addressType,
      ...addressType === 'e-proof' ? {
        newEAddress: newAddress,
      } : {
        newAddress,
      },
    },
  });

  return result;
};

export const getOrderAggregate = async (orderId) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'get',
    path: `${API_QUERY_ORDER_AGGREGATE}/${encodeURIComponent(orderId)}`,
  });

  return result;
};

export const updateOrderItemPrintConfig = async (orderId, store, itemId, request) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_UPDATE_ORDER_ITEM_PRINT_CONFIG,
    data: {
      orderId,
      store,
      itemId,
      ...request,
    },
  });

  return result;
};

export const updateOrderItemPromoConfig = async (orderId, store, itemId, request) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_UPDATE_ORDER_ITEM_PROMO_CONFIG,
    data: {
      orderId,
      store,
      itemId,
      ...request,
    },
  });

  return result;
};

export const topupCancel = async (orderId, store, itemId, fromOrderItem) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_TOPUP_CANCEL,
    data: {
      orderId,
      store,
      itemId,
      fromOrderItem,
    },
  });

  return result;
};

export const topupResolve = async (orderId, store, itemId, fromOrderItem) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_TOPUP_RESOLVE,
    data: {
      orderId,
      store,
      itemId,
      fromOrderItem,
    },
  });

  return result;
};

export const updateOrderItemDeadline = async (orderId, store, itemId, request) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_UPDATE_ORDER_ITEM_DEADLINE,
    data: {
      orderId,
      store,
      itemId,
      ...request,
    },
  });

  return result;
};

export const getPrintQNewOptions = async (ppcCode, productParts = ['tp_businesscards', 'tp_flyers']) => {
  const query = new URLSearchParams({
    priceInstanceCode: ppcCode,
    metadataName: 'product_part_options',
    productParts,
  }).toString();
  const result = await BackOfficeUtils.request('ppc_service', {
    method: 'get',
    path: `${API_GET_PRINTQ_NEW_OPTIONS}?${query}`,
  });

  return result;
};

export const getNewFileTypes = async (ppcCode) => {
  const query = new URLSearchParams({
    priceInstanceCode: ppcCode,
    metadataName: 'page_option',
    optionCode: 'file_type',
  }).toString();
  const result = await BackOfficeUtils.request('ppc_service', {
    method: 'get',
    path: `${API_GET_PRINTQ_NEW_OPTIONS}?${query}`,
  });

  return result;
};

export const getOrderItemActions = async (itemAggregateId) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'get',
    path: `${API_GET_ORDER_ITEM_ACTIONS}/${itemAggregateId}`,
  });

  return result;
};

export const proofRequestAction = async (data) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_PROOF_REQUEST,
    data,
  });

  return result;
};

export const proofCancelAction = async (data) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_PROOF_CANCEL,
    data,
  });

  return result;
};

export const proofFeedbackAction = async (data) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_PROOF_FEEDBACK,
    data: {
      ...data,
    },
  });

  return result;
};

export const changeFileTypeAction = async (data) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_CHANGE_FILETYPE,
    data,
  });

  return result;
};

export const removeArtworkAction = async (data) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_REMOVE_ARTWORK,
    data,
  });

  return result;
};

export const removeUploadArtworkAction = async (data) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_REMOVE_UPLOAD_ARTWORK,
    data,
  });

  return result;
};

export const skipUploadTimerAction = async (data) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_SKIP_UPLOAD_TIMER,
    data,
  });

  return result;
};

export const forceStartPrepressWithoutPayAction = async (data) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_FORCE_START_PREPRESS,
    data,
  });

  return result;
};

export const uploadArtworkAction = async (data) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_UPLOAD_ARTWORK,
    data,
  });

  return result;
};

export const getItemHistories = async (itemAggregateId) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'get',
    path: `${API_GET_ORDER_ITEM_HISTORY}/${itemAggregateId}`,
  });

  return result;
};

export const getOrderHistoryPayload = async (aggregateId) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'get',
    path: `${API_GET_ORDER_HISTORY}/${aggregateId}`,
  });

  return result;
};

export const getUploadPresignUrl = (uuid, filename, fileType) => BackOfficeUtils.request('item_state_service', {
  method: 'get',
  path: `api/presign/put/${encodeURIComponent(uuid)}?filename=${encodeURIComponent(filename)}&filetype=${encodeURIComponent(fileType)}`,
}).then((res) => res.data);

export const getS3CredentialForUpload = (uuid, filenames) => BackOfficeUtils.request('item_state_service', {
  method: 'get',
  path: `api/s3/credential-for-upload/${encodeURIComponent(uuid)}?${(() => {
    const url = new URL('https://localhost/');

    filenames.map((f) => url.searchParams.append('filenames[]', f));

    return url.searchParams.toString();
  })()}`,
}).then((res) => res.data);

export const addSupplierQuotation = async (data) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_ADD_SUPPLIER_QUOTATION,
    data,
  });

  return result;
};

export const removeSupplierQuotation = async (data) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_REMOVE_SUPPLIER_QUOTATION,
    data,
  });

  return result;
};

export const addCustomerPurchasingOrder = async (data) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_ADD_CUST_PO,
    data,
  });

  return result;
};

export const removeCustomerPurchasingOrder = async (data) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_REMOVE_CUST_PO,
    data,
  });

  return result;
};

export const cancelOrder = async (data) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_CANCEL_ORDER,
    data,
  });

  return result;
};

export const holdOrder = async (data) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_HOLD_ORDER,
    data,
  });

  return result;
};

export const unholdOrder = async (data) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_UNHOLD_ORDER,
    data,
  });

  return result;
};

export const getOrderComments = async ({
  orderAggregateId, departments, skip: _skip = null, itemId = null,
}) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'get',
    path: API_ORDER_COMMENTS,
    params: {
      orderAggregateId: encodeURIComponent(orderAggregateId),
      departments: encodeURIComponent(departments),
      ...(itemId == null ? {} : {
        itemId,
      }),
      _perPage: 30,
      ...(_skip == null ? {} : {
        _skip,
      }),
    },
  });

  return result;
};

export const postOrderComments = async ({
  orderAggregateId,
  comment,
  itemIds,
  department,
}) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_ORDER_COMMENTS,
    data: {
      orderAggregateId,
      comment,
      itemIds,
      department,
    },
  });

  return result;
};

export const getPrepressItemDetails = async (store, orderId, itemId) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'get',
    path: `${API_PREPRESS_VIEW_ITEM}/${store}+${orderId}+${itemId}`,
  });

  return result;
};

export const replayExternalMessage = async (aggregateId, eventNumber, callbackName) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'post',
    path: API_REPLAY_EXTERNAL,
    data: {
      aggregateId, eventNumber, callbackName,
    },
  });

  return result;
};

export const checkDeadline = async (aggregateId, reason, timestamp) => {
  const result = await BackOfficeUtils.request('item_state_service', {
    method: 'get',
    path: `${API_CHECK_DEADLINE}/${aggregateId}/reason/${reason}/@at/${timestamp}`,
  });

  return result;
};
