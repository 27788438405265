import { getSalesRequest } from 'lib/Sales/services/sales';
import { apiAction } from 'lib/common/middleware/backOfficeApiMiddleware';

export const RECEIVE_SALES_ACTIONS = 'RECEIVE_SALES_ACTIONS';
export const REQUEST_SALES_ACTIONS = 'REQUEST_SALES_ACTIONS';
export const UPDATE_DASHBOARD_REQUEST = 'UPDATE_SALES_DASHBOARD_REQUEST';
export const RECEIVE_SALES = 'RECEIVE_SALES';
export const UPDATE_FILTER = 'UPDATE_SALES_FILTER';

function receiveSales(data) {
  return {
    type: RECEIVE_SALES,
    sales: data['hydra:member'],
    total: data['hydra:totalItems'],
  };
}

export function updateRequest(request) {
  return {
    type: UPDATE_DASHBOARD_REQUEST,
    request,
  };
}

export function fetchSales(request) {
  const { filter, pagination, order } = request;

  return apiAction({
    request: getSalesRequest(filter, pagination, order),
    onSuccess: receiveSales,
    label: 'sales',
  });
}

export function updateFilter(filter) {
  return (dispatch) => {
    dispatch(fetchSales(filter));
    return {
      type: UPDATE_FILTER,
      filter,
    };
  };
}
