export const ADD_TAB = 'ITEM_STATE_ADD_TAB';
export const REMOVE_TAB = 'ITEM_STATE_REMOVE_TAB';
export const ACTIVATE_TAB = 'ITEM_STATE_ACTIVATE_TAB';

export function addTab(tabCode, tabLabel, tabProps) {
  return {
    type: ADD_TAB,
    tabCode,
    tabLabel,
    tabProps,
  };
}

export function removeTab(tabCode) {
  return {
    type: REMOVE_TAB,
    tabCode,
  };
}

export function activateTab(tabCode) {
  return {
    type: ACTIVATE_TAB,
    tabCode,
  };
}
