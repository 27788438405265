import { apiAction } from 'lib/common/middleware/backOfficeApiMiddleware';
import { getProductionItemRequest } from 'lib/Production/services/production';

export const RECEIVE_DIGITAL_PROOF_ITEMS_RESPONSE = 'RECEIVE_DIGITAL_PROOF_ITEMS_RESPONSE';
export const UPDATE_DIGITAL_PROOF_REQUEST = 'UPDATE_DIGITAL_PROOF_REQUEST';

const receiveDigitalProofItems = (country) => (data) => ({
  type: RECEIVE_DIGITAL_PROOF_ITEMS_RESPONSE,
  country,
  items: data['hydra:member'],
  total: data['hydra:totalItems'],
});

export function fetchDigitalProofItems(request) {
  const {
    filter, pagination, order, country,
  } = request;
  return apiAction(
    {
      request: getProductionItemRequest({
        ...filter,
        workflowName: 'production_item_digital_proof',
      }, pagination, order),
      onSuccess: receiveDigitalProofItems(country),
      label: `digitalProof.${country}`,
    },
  );
}

export function updateDigitalProofRequest(request) {
  return {
    ...request,
    type: UPDATE_DIGITAL_PROOF_REQUEST,
  };
}
