import { apiAction } from 'lib/common/middleware/backOfficeApiMiddleware';
import { getShippingJobRequest } from 'lib/Shipping/services/shipping';

export const RECEIVE_INBOUND_ITEMS_RESPONSE = 'RECEIVE_INBOUND_ITEMS_RESPONSE';
export const UPDATE_INBOUND_ITEMS_REQUEST = 'UPDATE_INBOUND_ITEMS_REQUEST';

const receiveAllItems = (country) => (data) => ({
  type: RECEIVE_INBOUND_ITEMS_RESPONSE,
  country,
  items: data['hydra:member'],
  total: data['hydra:totalItems'],
});

export function fetchItems(request) {
  const {
    filter, pagination, order, country,
  } = request;
  return apiAction(
    {
      request: getShippingJobRequest({
        ...filter,
        country,
      }, pagination, order),
      onSuccess: receiveAllItems(country),
      label: `inbound.${country}`,
    },
  );
}

export function updateRequest(request) {
  return {
    ...request,
    type: UPDATE_INBOUND_ITEMS_REQUEST,
  };
}
