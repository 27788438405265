import { API_END, API_START } from 'lib/common/actions/types';

const initialState = { isFetching: {} };

function backOfficeApi(state = initialState, action) {
  switch (action.type) {
    case API_START:
      return {
        ...state,
        loading: { ...state.isFetching, [action.payload]: true },
      };
    case API_END:
      return {
        ...state,
        loading: { ...state.isFetching, [action.payload]: false },
      };
    default:
      return state;
  }
}

export default backOfficeApi;
