import config from 'config';

export const getCountiesSelect = (countries) => {
  const { storeCountries } = config;

  return storeCountries.reduce(
    (options, country) => {
      if (!countries || countries.includes(country.id.toUpperCase())) {
        options.push({ value: country.id.toUpperCase(), label: country.label });
      }
      return options;
    },
    [],
  );
};

export const getStoreCountryById = (id) => {
  const { storeCountries } = config;

  return storeCountries.find((country) => id.toUpperCase() === country.id.toUpperCase());
};
