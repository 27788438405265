import {
  getBlankDateFilter,
  getBlankRangeFilter,
  getDateFilter,
  getMappedFilter,
  getRangeFilter,
} from 'lib/common/filters';
import { generatePath } from 'react-router-dom';

const API_SALES = 'api/organizations';

export const getDefaultSalesFilter = () => ({
  type: [],
  industry: [],
  size: [],
  accountOwner: [],
  serviceLevel: [],
  status: [],
  registrationDate: getBlankDateFilter(),
  firstOrderDate: getBlankDateFilter(),
  lastOrderDate: getBlankDateFilter(),
  lastOrderValue: getBlankRangeFilter(),
  lastYearOrders: getBlankRangeFilter(),
  lastYearOrdersValue: getBlankRangeFilter(),
  lifeTimeOrdersValue: getBlankRangeFilter(),
  lifeTimeOrders: getBlankRangeFilter(),
  onlyStoreData: false,
  showGroups: true,
});

export function getSalesRequest(filter, pagination, order) {
  // Create Base filter
  const params = {
    _page: pagination.page,
    _perPage: pagination.perPage,
    _order: order,
    ...getMappedFilter(
      filter,
      {
        store: 'store',
        type: 'type',
        industry: 'industry',
        serviceLevel: 'serviceLevel',
        accountOwner: 'accountOwner.id',
        size: 'companySize',
        status: 'status',
        referenceNumber: 'referenceNumber',
      },
    ),
  };

  // Filter Dates
  params['organizationStoreData.registrationDate'] = getDateFilter(filter.registrationDate);
  params['organizationStoreData.firstOrder'] = getDateFilter(filter.firstOrderDate);
  params['organizationStoreData.lastOrder'] = getDateFilter(filter.lastOrderDate);

  // Filter Numbers
  params['organizationStoreData.lastOrderValue'] = getRangeFilter(filter.lastOrderValue);
  params['organizationStoreData.lastYearOrders'] = getRangeFilter(filter.lastYearOrders);
  params['organizationStoreData.lastYearOrdersValue'] = getRangeFilter(filter.lastYearOrdersValue);
  params['organizationStoreData.lifeTimeOrdersValue'] = getRangeFilter(filter.lifeTimeOrdersValue);
  params['organizationStoreData.lifeTimeOrders'] = getRangeFilter(filter.lifeTimeOrders);

  // Custom Filters
  params.exists = {};
  if (filter.onlyStoreData) {
    params.exists.organizationStoreData = true;
  }

  if (filter.showGroups) {
    params.exists.parent = false;
  }

  return {
    service: 'customer', method: 'GET', path: API_SALES, params,
  };
}

export const getOrgUrl = (store, id) => generatePath('/sales/sales-dashboard/:store/:id', { store, id });
