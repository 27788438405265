import {
  RECEIVE_STAFFS,
  UPDATE_DASHBOARD_REQUEST,
  UPDATE_DASHBOARD_MODAL,
} from '../actions/staffs';
import { API_END, API_START } from '../../common/actions/types';
import { getDashboardDefaultFilter } from '../services/staffs';

const initialState = {
  dashboard: {
    staffs: [],
    filter: getDashboardDefaultFilter(),
    order: { createdAt: 'DESC' },
    pagination: {
      page: 1,
      perPage: 10,
    },
    total: 0,
    modal: {
      type: '',
      data: {},
    },
    loading: false,
  },
};

function staffs(state = initialState, action) {
  switch (action.type) {
    case API_START:
      if (action.payload === 'staffs') {
        return { ...state, dashboard: { ...state.dashboard, loading: true } };
      }
      break;
    case API_END:
      if (action.payload === 'staffs') {
        return { ...state, dashboard: { ...state.dashboard, loading: false } };
      }
      break;
    case RECEIVE_STAFFS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          staffs: action.staffs,
          total: action.total,
        },
      };
    case UPDATE_DASHBOARD_REQUEST:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          filter: action.request.filter,
          pagination: action.request.pagination,
          order: action.request.order,
        },
      };
    case UPDATE_DASHBOARD_MODAL:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          modal: action.modal,
        },
      };
    default:
      return state;
  }

  return state;
}

export default staffs;
